@import "./colours.scss";

/* Toggle Styles */

#wrapper {
  padding-left: 0;
  min-height: 100vh;
  // -webkit-transition: all 0.2s ease;
  // -moz-transition: all 0.2s ease;
  // -o-transition: all 0.2s ease;
  // transition: all 0.2s ease;
}

$topbar-height: 66px;

#sidebar-wrapper {
  z-index: 999;
  position: fixed;
  left: 260px;
  width: 0;
  height: calc(100% - $topbar-height);
  margin-left: -260px;
  overflow-x: hidden;
  // -webkit-transition: all 0.2s ease;
  // -moz-transition: all 0.2s ease;
  // -o-transition: all 0.2s ease;
  // transition: all 0.2s ease;
  margin-top: $topbar-height;
  display: grid;
  grid-template-rows: 1fr auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($secondary, 1);
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba($panel, 1) !important;
  }

  &:hover::-webkit-scrollbar {
    width: 10px;
  }
}

#wrapper.toggled #sidebar-wrapper {
  width: 260px;
  margin-left: 0;
}

#page-content-wrapper {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  // padding-left: 260px;
  padding-top: $topbar-height;
  // transition: all 0.2s ease;

  &.no-page-padding-top {
    padding-top: 0;
  }
}

/* Sidebar Styles */

.sidebar-nav {
  width: 260px;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
  background: $panel;
  border-color: rgba(255, 255, 255, 0.04) !important;
  border-right: 1px solid;
  padding-top: 1rem;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: rgba($white, 0.7);
  font-weight: 500;
  font-size: 14px;
  margin: 0rem 1rem;
  padding: 0.4rem 1rem;
  border-radius: 0.3rem;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: $white;
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-brand {
  font-size: 18px;
  padding-top: 1.5rem;
  line-height: 0;
  margin-left: 1.5rem;
}

.sidebar-buttons {
  margin-left: 1.5rem;
}

.sidebar-heading {
  margin-left: 1.5rem;
  margin-bottom: 0.25rem;
  margin-top: 0.75rem;
  opacity: 0.5;
  font-size: 13px;
}

.sidebar-heading-icon {
  margin-right: 0.75rem;
}

.active-link {
  color: $white !important;
  background-color: $secondary;
}

#overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
}
.toggled #page-content-wrapper {
  position: relative;
  z-index: 2;
}
.toggled #overlay {
  display: block;
}

.no-padding {
  padding-left: 0 !important;
}

.sidebar {
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

/* Visible state for the sidebar */
.sidebar.visible {
  transform: translateX(0);
}

#topbar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $topbar-height;
  background: $panel;
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
  z-index: 1000;
  transition: 0.15s;

  &.topbar-wrapper-transparent {
    background: none;
  }
}

.topbar-nav {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: center;

  .topbar-nav-right {
    display: flex;
    justify-content: flex-end;
  }

  .topbar-nav-right {
    display: flex;
    justify-content: flex-end;
  }

  .topbar-nav-center {
    grid-column: 2;
  }

  .button {
    white-space: nowrap;
  }
}

.topbar-mobile-search-bar-container {
  position: absolute;
  width: 100%;
  z-index: 1000;
  top: 0;
  height: $topbar-height;
  background-color: $panel;

  .topbar-mobile-search-bar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 1rem;

    .close-search-button {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0 1.8rem;
      color: $white;
      height: 100%;
      font-size: 1.5rem;
      cursor: pointer;
      z-index: 4;
      background: none;
      border: none;
      padding: 0;
    }
  }
}

.mobile-fixed-bottom {
  position: sticky;
  bottom: 0;
  padding: 0.75rem 0rem;
  width: 100%;
  background-color: $panel-inner;
  border-top: 1px solid lighten($panel-inner, 5%) !important;
  font-size: 11px;

  .sidebar-heading {
    margin-top: 0;
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  #wrapper {
    padding-left: 260px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 260px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }

  #page-content-wrapper {
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

@media (max-width: 992px) {
  #wrapper.toggled #sidebar-wrapper {
    width: 100%;
  }

  #sidebar-wrapper {
    left: 100%;
    margin-left: 0;
  }

  .sidebar-nav {
    width: 100%;
  }

  #page-content-wrapper {
    overflow: hidden;
  }
}
