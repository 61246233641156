@import "scss/colours.scss";

.like-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(lighten($secondary, 5%), 0.9);
  color: rgba($white, 0.7);
  border: none;
  padding: 0;
  font: inherit;
  font-size: 15px !important;
  cursor: pointer;
  outline: inherit;
  transition: 0.2s;
  padding: 3px 8px;
  min-width: 52px;
  max-height: 27px;
  border-radius: 0.5rem;
  overflow: hidden !important;

  &.shadow {
    box-shadow: 0 0 12px rgba($bg, 0.4) !important;

    &:hover {
      box-shadow: 0 0 12px rgba($bg, 0.4) !important;
    }
  }

  &:hover {
    background: rgba(lighten($secondary, 10%), 0.9);
  }

  &:hover .like-number {
    opacity: 1;
  }

  &:hover .icon-default {
    color: $white;
    opacity: 1;
    font-size: 16px;
  }

  &.toggled {
    background-color: rgba(#4851b6, 1);

    &:hover {
      background-color: lighten(rgba(#4851b6, 1), 5%);
    }
  }

  &.large {
    padding: 6px 10px;
    max-height: 33px;
  }
}

.like-button .icon-default {
  color: $white;
  opacity: 0.7;
  transition: 0.2s;
  font-size: 16px;
}

.like-button .icon-toggled {
  color: $white;
  animation: pulse 0.3s ease;
  font-size: 16px;
}

.like-button .icon-toggled:hover {
  color: $white;
}

.like-number {
  color: $white;
  font-size: 14px;
  font-weight: 600 !important;
  opacity: 0.9;
  transition: 0.2s;
}

.like-number-wrapper {
  position: relative;
  display: flex;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
