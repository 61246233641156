@import "scss/colours.scss";

.fy-record-toggle {
	appearance: none;
	border: none;
	background: none;
	display: inline-flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	color: #fff;
	font-size: 0.75rem;

	* {
		pointer-events: none;
	}

	svg {
		display: block;
		height: 2rem;
		width: 2rem;
		margin-bottom: 0.25rem;

		&.fy-record-toggle-record {
			circle {
				stroke: $primary;
			}
			rect {
				fill: $primary;
			}
			&:hover {
				circle {
					stroke: $primary-light;
				}
				rect {
					fill: $primary-light;
				}
			}
		}

		&.fy-record-toggle-stop {
			circle {
				stroke: $secondary-lightest;
			}
			rect {
				fill: $secondary-lightest;
			}
			&:hover {
				circle {
					stroke: #fff;
				}
				rect {
					fill: #fff;
				}
			}
		}

		circle,
		rect {
			transition: all 0.25s ease-in-out;
		}

		circle {
			fill: none;
			stroke-width: 2px;
		}
	}
}
