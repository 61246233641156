@import "scss/colours.scss";

.fy-uploader, .fy-uploader-no-style {
  [type="file"] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;

    + label {
      cursor: pointer;
    }
  }
  .fy-uploader-layout {
    display: flex;
    align-items: center;
    width: 100%;

    .fy-uploader-layout-icon {
      font-size: 2.625rem;
      margin-right: 1rem;
    }
  }
}

.fy-uploader {
    [type="file"] {

    + label {
      border: dashed 2px rgba(255, 255, 255, 0.12) !important;
      border-radius: 0.5rem;
      transition: 0.1s all;
      &:hover {
        background-color: lighten($panel, 6%);
      }
    }
  }
}
