@import "scss/colours.scss";

.skeleton {
  background: linear-gradient(
    -90deg,
    rgba($white, 10%) 25%,
    rgba(darken($white, 20%), 10%) 50%,
    rgba($white, 10%) 75%
  );
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 0.3rem;
  width: 100%;
  min-height: 1em;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.skeleton.rounded {
  border-radius: 50%;
}

.skeleton.medium {
  width: 40%;
}

.skeleton.short {
  width: 20%;
}
