@import "scss/colours.scss";

.fy-dropdown-menu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  background-color: $panel-inner;
  border: 1px solid lighten($panel-inner, 8%);
  border-radius: 0.5rem;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  overflow: hidden;
}

.fy-dropdown-item {
  padding: 10px 15px;
  text-decoration: none;
  display: block;
  color: $white !important;
  transition: 0.15s all;
  cursor: pointer;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.fy-dropdown-item:hover {
  background-color: lighten($panel-inner, 8%);
}
