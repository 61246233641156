@import "scss/colours.scss";

.cover-img {
  overflow: hidden;
  border-radius: 0.5rem;
  border: 2px solid rgba($white, 0.8);
  margin-right: 12px;
  position: relative;
  background-color: #57577d;
  transition: all 0.2s;

  &.cover-img-hoverable {
    &:hover {
      cursor: pointer;
      transition: all 0.2s;
      border: 2px solid $primary;
    }
  }

  img,
  .cover-img-edit {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .cover-img-edit {
    padding: 0.25rem;
    font-size: 0.75rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.25s ease-in-out;
    color: transparent;

    span {
      text-align: center;
    }

    svg {
      position: absolute;
      color: #fff;
      transition: background 0.25s ease-in-out;
      background: rgba(#000, 0.5);
      border-radius: 0.25rem;
      padding: 0.5rem;
      right: 0.25rem;
      bottom: 0.25rem;
    }

    &:hover {
      color: #fff;
      background: rgba(#000, 0.5);
      svg {
        background: none;
      }
    }
  }
}
