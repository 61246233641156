@import "scss/colours.scss";

.topbar-bg-transparent {
  background-color: transparent !important;
  border-bottom: 1px solid transparent !important;
}

.topbar-bg-dark {
  background-color: $bg !important;
  border-bottom: 1px solid transparent !important;
}

.topbar-hide-top {
  margin-top: -66px;
}
