@import "scss/colours.scss";
@import "scss/_breakpoint.scss";


.image-upload-frame {
	// aspect-ratio: 4/3;
	@include breakpoint(min-width 992px) {
		aspect-ratio: 1/1;
	}
}

.fy-image-uploader {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	height: 100%;
	width: 100%;

	.file-preview {
		height: 100%;
		width: 100%;
		border-radius: .5rem;
		object-fit: cover;
	}
	.image-upload-label, .upload-details {
		background-color: rgba($panel, .85);
		position: absolute;
	}
	.image-upload-label {
		border-radius: .5rem .5rem 0 0;
		display: flex;
		width: 100%;
		top: 0;
		padding: 1rem;
	}

	.upload-details {
		top: 0;
		width: 100%;
		padding: 1rem;
		border-radius: 0;
	}

	.placeholder-icon {
		height: 10rem;
		opacity: .15;
	}

	.image-placeholder {
		// background-color: red;
		fill: $secondary;
	}
}