@import "scss/colours.scss";
@import "scss/site-refresh.scss";

.cta-ai-face-mirror {
  margin-top: 1rem;
}

.cta-ai-face-mirror-body {
  height: 400px;
  overflow: hidden;
  display: block;
  border: 2px solid #35354b;
  border-radius: 0.5rem;
  position: relative;
  transition: all 0.25s ease-in-out;
  // margin-right: 0;

  &:hover {
    border-color: $primary;

    .cta-ai-face-mirror-tint {
      opacity: 0.66;
    }
  }

  video {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .cta-ai-face-mirror-tint {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;

    transition: all 0.25s ease-in-out;
    background: linear-gradient(
      133deg,
      rgba(#191933, 0.65) 0%,
      rgba(#191933, 0) 100%
    );
  }

  .cta-ai-face-mirror-overlay {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    gap: 1rem;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;

    h1 {
      color: #fff;
      text-shadow: 0px 0px 20px rgba(#191933, 0.6);
      margin-bottom: 0.25rem;
    }

    p {
      text-shadow: 0px 0px 10px rgba(#191933, 0.6);
      line-height: 1.25;
    }

    .cta-ai-face-mirror-button {
      height: 3rem;
      width: 120px;
      padding-left: 1rem;
      padding-right: 1rem;
      white-space: nowrap;
      filter: drop-shadow(0px 7px 5px rgba(#191933, 0.15));
    }
  }

  @media (max-width: 992px) {
    height: 260px;
  }
}
