@import "scss/colours.scss";

.fy-checkbox-frame {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  cursor: pointer;

  label {
    margin-left: 0.6rem;
    cursor: pointer;
  }
}

.fy-checkbox-primary-unchecked,
.fy-checkbox-primary-checked {
  .fy-checkbox {
    background-color: $primary;
  }
  &:hover {
    .fy-checkbox {
      background-color: lighten($primary, 5%);
    }
  }
}

.fy-checkbox-secondary-unchecked,
.fy-checkbox-secondary-checked {
  .fy-checkbox {
    background-color: $secondary;
  }
  &:hover {
    .fy-checkbox {
      background-color: $secondary-light;
    }
  }
}

.fy-checkbox-secondary-checked {
  .fy-checkbox {
    background-color: $primary;
  }
  &:hover {
    .fy-checkbox {
      background-color: lighten($primary, 5%);
    }
  }
}

.fy-checkbox {
  border-radius: 0.35rem;
  display: inline-block;
  vertical-align: middle;
  height: 1.5rem;
  width: 1.5rem;
  stroke: white;
  transition: 0.2s;
}

.fy-checkbox-disabled {
  opacity: 0.25;
  cursor: default;
  label {
    cursor: default;
  }

  &:hover {
    .fy-checkbox {
      background-color: $secondary;
    }
  }
}
