@import "scss/colours.scss";

.pagination {
  z-index: 1;

  .page-link {
    color: rgba($white, 75%) !important;
    background-color: $secondary;
    border: 1px solid $secondary;
    transition: 0.2s;

    &:hover {
      color: #fff;
      background-color: lighten($secondary, 4%);
      border-color: lighten($secondary, 4%);
    }

    &:focus {
      box-shadow: none;
    }
  }

  .active .page-link {
    color: $white !important;
    background-color: lighten($secondary, 15%);
    border-color: lighten($secondary, 15%);
  }

  .disabled .page-link {
    color: rgba($white, 20%) !important;
    background-color: $secondary;
    border-color: $secondary;
  }

  .page-item {
    &:last-child .page-link {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
    &:first-child .page-link {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
  }
}
