.fy-header {
  // padding-top: 7rem;
  padding-bottom: 3rem;
}

@media (max-width: 992px) {
  .fy-header {
    padding-top: 5rem;
    padding-bottom: 0rem;
  }
}
