@import "scss/colours.scss";

.social-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
}

.social-button-icon {
  font-size: 32px !important;
  height: 52px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 0px solid;
  margin-bottom: 10px;
  transition: 0.2s;

  @include social-colors;
}

.social-button-text {
  font-size: 12px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .social-button-icon {
    height: 56px;
    width: 56px;
  }

  .social-button-text {
    font-size: 13px;
    font-weight: 600;
  }
}
