// Default colours
$primary: #e66462;
$secondary: #39394c;
$action: #55556b;
$bg: #1a1a27;
$input: #39394c;
$input-placeholder: #aeaeb3;
$danger: #8f4951;
$input-dark: #39394c;
$info: #252d43;
$success: #40ad48;
$warning: darken(#f7941c, 5%);
$accent: #80cfd3;
$workflow: $primary;
$panel: #242433;
$panel-inner: #2b2b3b;
$panel-inner-border: lighten($panel, 8%);

$primary-lm: #000;

// Darken some of the grays, since the default bootswatch grays are almost impossible to see against white
$gray-100: #ecf0f1;
$gray-200: #dee2e6;
$gray-300: #ced4da;
$gray-400: #b4bcc2;
$gray-500: #a5b2b4;
// These have not been changed yet
$gray-600: #95a5a6 !default;
$gray-700: #7b8a8b !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000;
$white: #fff;

// Changes and new classes
$gray-750: #5c656e;
$light: $gray-300;
$lighter: $gray-200;
$medium: $gray-500;
$medium-dark: $gray-600;
$dark: $gray-750;

// lighter and darker colors
$primary-light: desaturate(lighten($primary, 20%), 15%);
$primary-dark: saturate(darken($primary, 15%), 10%);
$primary-very-light: desaturate(lighten($primary, 45%), 35%);

$secondary-dark: saturate(darken($secondary, 6%), 2%);
$secondary-darker: saturate(darken($secondary, 9%), 2%);
$secondary-very-dark: saturate(darken($secondary, 12%), 2%);
$secondary-light: lighten($secondary, 5%);
$secondary-lighter: lighten($secondary, 12%);
$secondary-very-light: lighten($secondary, 24%);
$secondary-lightest: lighten($secondary, 60%);

$info-light: desaturate(lighten($info, 15%), 15%);
$info-dark: darken($info, 10%);
$info-very-light: desaturate(lighten($info, 55%), 30%);

$success-light: desaturate(lighten($success, 15%), 15%);
$success-dark: darken($success, 10%);

$warning-light: desaturate(lighten($warning, 15%), 15%);
$warning-dark: darken($warning, 10%);

$danger-light: desaturate(lighten($danger, 15%), 15%);
$danger-dark: darken($danger, 10%);

$accent-light: desaturate(lighten($accent, 20%), 15%);
$accent-dark: saturate(darken($accent, 15%), 10%);

$badge-colors: (
  "gray": #5b7282,
  "red": #d91f11,
  "orange": #e86427,
  "yellow": #f5c518,
  "lime": #3c7d0e,
  "green": #077d55,
  "teal": #167b7d,
  "turquoise": #067a91,
  "aqua": #0073ba,
  "blue": #186ade,
  "ultramarine": #535fe8,
  "purple": #8f49de,
  "pink": #cc1d92,
  "primary": $primary,
);

@mixin social-colors {
  color: $white;

  &:hover {
    opacity: 0.8;
  }

  &.bg-facebook {
    background-color: #4267b2;
  }

  &.bg-reddit {
    background-color: #ff5700;
  }

  &.bg-reddit {
    background-color: #ff5700;
  }

  &.bg-whatsapp {
    background-color: #25d366;
  }

  &.bg-x {
    background-color: #000000;
  }

  &.bg-email {
    background-color: #858585;
  }

  &.bg-download {
    background-color: $primary;
  }
}
