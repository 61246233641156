@import "scss/colours.scss";

.fy-action-button {
  appearance: none;
  background-color: rgba(lighten($secondary, 5%), 0.9);
  border: none;
  border-radius: 0.5rem;
  color: #fff;
  display: flex;
  gap: 6px;
  align-items: center;
  // background: red;
  line-height: 1;
  font-size: 15px;
  font-weight: 500;
  text-align: right;
  min-height: 2.25rem;
  min-width: 1rem;
  position: relative;
  padding: 0 0 0 12px;
  transition: all 0.15s;
  // text-align: right;

  &:hover {
    background: rgba(lighten($secondary, 10%), 0.9);
  }

  &.color-action {
    background-color: rgba(lighten($action, 12%), 0.9);

    &:hover {
      background: rgba(lighten($action, 18%), 0.9);
    }
  }

  svg {
    width: 1em;
    color: $white;
    margin-right: 14px;
  }

  .fy-action-button-icon-toggled {
    animation: pulse 0.3s ease;
  }
}
.bookmark-action-button {
  svg {
    color: $white;
  }
  &.action-button-toggled {
    background-color: darken(rgba(map-get($badge-colors, "green"), 1), 4%);

    &:hover {
      background-color: darken(rgba(map-get($badge-colors, "green"), 1), 0%);
    }
  }

  .fy-workdots-label {
    min-width: 1.8rem;
  }
}

.feature-action-button {
  svg {
    color: $white;
  }
  &.action-button-toggled {
    background-color: darken(rgba(map-get($badge-colors, "purple"), 0.9), 20%);

    &:hover {
      background-color: darken(
        rgba(map-get($badge-colors, "purple"), 0.9),
        25%
      );
    }
  }

  .fy-workdots-label {
    min-width: 2rem;
  }
}

.like-action-button {
  svg {
    color: $white;
  }
  &.action-button-toggled {
    background-color: rgba(#4851b6, 1);

    &:hover {
      background-color: lighten(rgba(#4851b6, 1), 5%);
    }
  }
  .fy-workdots-label {
    min-width: 8px;
  }
}
