@import "scss/colours.scss";

.fy-scene-3d-fullscreen {
  height: calc(100vh - 66px);
  width: 100%;
  border-radius: 0rem;
}

.fy-scene-3d-default {
  height: 900px;
  width: 100%;
  border-radius: 0.5rem;
}

.fy-scene-3d-fullscreen-intro {
  height: calc(100vh - 66px - 66px);
  width: 100%;
  border-radius: 0rem;
}
