@import "scss/colours.scss";
@import "scss/site-refresh.scss";

.fy-notification-container {
	pointer-events: none;
	position: fixed;
	// background: red;
	height: calc(100vh - 66px);
	gap: 1rem;
	width: 28rem;
	top: 66px;
	right: 0;
	z-index: 1000;
	padding: 0  1rem;

	.fy-yadda {
		position: absolute;
		bottom: 0;
		margin: auto auto .5rem;
	}

	button {

	pointer-events: auto;
	}
}

.fy-live-notification {
	@include panel;
	pointer-events: auto;
	overflow: hidden;
	border-radius: .5rem;
	width: 100%;
	margin-top: 1rem;
	padding: 1rem;
	// transform: translateY(-100%);
	position: absolute;

	header {
		display: flex;
		justify-content: space-between;

		@include close-parent;
	}

	h5 { margin-bottom: 0;}

	span {
		display: block;
		font-size: .875rem;
		opacity: .66;
	}

	.fy-notification-lifeline {
		position: absolute;
		left: 0;
		top: 0;
		// width:%;
		height: 2px;
		background: $primary;
	}

	.fy-notification-actions {
		margin-top: .5rem;
		display: grid;
		grid-template-columns: 1fr 1fr;
    // grid-auto-columns: minmax(0, 1fr);
		gap: .5rem;
	}

	&.fy-clicky-notification:hover {
		cursor: pointer;
		&:hover {
			background: lighten($panel, 2%)
		}
	}
}