@import "scss/colours.scss";
@import "scss/site-refresh.scss";

.fy-slide-frame {
  position: absolute;
  height: 100%;
  width: 100%;
}

.fy-entity-input {
  @include panel;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  width: 100%;

  .fy-entity-input-crop-container-hidden {
    cursor: default;
    background-color: rgba($secondary-very-dark, 0.75);

    .fy-entity-input-crop-area {
      color: rgba(#f00, 0);
    }
  }

  .fy-entity-input-crop-area {
    color: rgba($secondary-very-dark, 0.75);
  }

  .fy-entity-input-crop-media {
    box-shadow:
    // 0 0 0 1px rgba(#000, 0.16),
      0 0 15px rgb(#000, 0.5);
  }

  .fy-entity-input-full {
    display: grid;
  }

  &.fy-entity-input-narrow {
    min-height: 400px;
    // background-color: red;

    .fy-entity-input-full {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;

      & > div {
        width: 100%;
      }
    }
  }

  &.fy-entity-input-wide {
    min-height: 300px;
    // background-color: red;

    .fy-entity-input-full {
      grid-template-columns: 1fr fit-content(50%);
    }
  }

  .fy-entity-input-play-button {
    @include video-tint;
    border: none;
    left: 0;
    right: 0;
    bottom: 1rem;
    margin: auto;
    width: fit-content;
    // background-color: red;
    position: absolute;
  }

  .fy-entity-input-crop-tip {
    @include video-tint;
    position: absolute;
    background-color: rgba($secondary-very-dark, 0.5);
    padding: 0.5rem;
    border-radius: 0.5rem;
    color: rgba(#fff, 0.5);
    pointer-events: none;
  }

  // .fy-entity-input-progress-indicator {
  //   align-items: center;
  //   display: flex;
  //   flex-direction: column;
  //   position: relative;

  //   .fy-entity-input-progress-number {
  //     position: absolute;
  //     text-align: center;
  //     font-size: 0.625rem;
  //     line-height: 0.625rem;
  //     height: 0.625rem;
  //     top: 50%;
  //     transform: translateY(-50%);
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //   }
  // }
}

.fy-entity-input-landscape {
  width: 100%;
  aspect-ratio: 4/3;
  max-height: 66vh;
}

.fy-entity-input-empty,
.fy-entity-input-full {
  // position: relative;
  justify-content: center;

  .fy-entity-input-icon {
    position: absolute;
    height: 80%;
    opacity: 75%;
    margin: auto;
    inset: 0;

    path {
      fill: $panel-inner-border;
    }
  }

  .fy-entity-input-file-details {
    padding: 0 0.5rem;
    div {
      font-size: 0.75rem;
    }
  }
}

.fy-entity-input-label-icon {
  margin-right: 12px;
  height: 28px;
}

.fy-entity-input-mocap-preview {
  position: absolute;
  border-radius: 0.5rem;
  border: 1px solid rgba(#fff, 0.2);
  display: block;
  vertical-align: bottom;
  height: 100%;
  width: 100%;
  margin: 0;
}

.fy-entity-input-media-preview {
  position: relative;
  padding: 0.5rem;
}

.fy-entity-input-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    margin-top: 1rem;
  }
}

.fy-entity-input-preview-controls {
  // background-color: green;
  display: grid;
  // background-color: red;
  grid-template-rows: 1fr auto;

  background-color: $secondary-dark;
  gap: 0.5rem;
  padding: 1rem;
}

.fy-entity-input-preview-tools-narrow,
.fy-entity-input-preview-tools-wide {
  display: grid;
  // grid-template-columns: auto 1fr;
  gap: 0.5rem;
  overflow: hidden;
  border-radius: 0.5rem 0.5rem 0 0;

  .fy-zoom-slider {
    padding: 0.5rem 0;
  }
}

.fy-entity-input-preview-tools-wide {
  grid-template-columns: auto 1fr;
}

.fy-entity-input-preview-tools-narrow {
  grid-template-rows: auto 1fr;
}

.fy-entity-input-empty-controls {
  // background-color: red;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1rem;
  gap: 1rem;

  .fy-entity-input-row {
    height: 100%;
  }

  .fy-entity-input-button {
    background-color: rgba(#000, 0.15);
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    transition: 0.25s all ease-in-out;
    color: rgba(#fff, 0.66);

    &:hover {
      background-color: rgba(#000, 0.1);
      color: rgba(#fff, 1);
    }

    label {
    }
  }

  .fy-entity-input-upload-detail {
    span {
      opacity: 0.66;
      display: block;
      font-size: 0.75rem;
      line-height: 1em;
      height: 0.75rem;
    }
  }
}
