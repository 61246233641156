.fy-webcam-not-supported {
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: rgba(#fff, 0.66);

	svg {
		color: rgba(#fff, 0.33);
		height: 15%;
		margin-bottom: 1rem;
	}
}
