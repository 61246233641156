@import "scss/colours.scss";

.fy-temp-select {
  margin-bottom: 1rem;

  &:only-child,
  &:last-child {
    margin-bottom: 0;
  }

  .select-container {
    overflow: hidden;
  }

  .select-option {
    background: none;
  }

  .select-option.select__option--is-selected {
    background-color: $secondary-lighter;
    &.select__option--is-focused {
      background-color: $secondary-very-light;
    }
  }

  .select__value-container--is-multi {
    height: min-content;
    // background: red;
    display: inline-block;
    white-space: nowrap;
    overflow-x: scroll;
    border-radius: .5rem;
    // flex-wrap: 
    // gap: .25rem;
    padding: 0;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  .select__input-container {
    min-width: 10rem;
    // background: red;
    padding: 0 0 0 .5rem;
    margin: 0;}
  }

  [class^="select__multi-value"] {
    color: #fff;
  }

  .select__multi-value {
    display: inline-flex;
    border-radius: .5rem;
    overflow: hidden;
    padding: 0 0 0 .125rem;
    background: $secondary-lighter;
    margin-bottom: 0;
    margin-top: 0;
    width: fit-content;
  }

  .select__multi-value__remove {
    display: flex;
    justify-content: center;
    border-radius: 0;
    width: 1.5rem;

    &:hover {
      color: #fff;
      background: $secondary-very-light;
    }
  }
}

.d-flex:not(.flex-column) > .fy-temp-select {
  margin-bottom: 0;
  margin-right: 0.5rem;

  &:only-child,
  &:last-child {
    margin-bottom: 0;
  }
}

.gap-1 > .fy-temp-select,
.gap-2 > .fy-temp-select,
.gap-3 > .fy-temp-select,
.gap-4 > .fy-temp-select,
.gap-5 > .fy-temp-select {
  margin-bottom: 0;
  margin-right: 0 !important;
}
