@import "scss/colours.scss";
@import "scss/site-refresh.scss";

.fy-zoom-slider {
  display: flex;

  &.fy-zoom-slider-vertical {
    height: 100%;
    flex-direction: column;

    .fy-zoom-slider-container {
      height: 100%;
      margin: 1.5rem 0;
    }

    .fy-zoom-slider-track {
      height: 100%;
      width: 2.25rem;
      justify-content: center;
    }

    .fy-zoom-slider-track-bar {
      width: 0.5rem;
      height: 100%;
    }
  }

  &.fy-zoom-slider-horizontal {
    width: 100%;
    flex-direction: row-reverse;

    .fy-zoom-slider-container {
      width: 100%;
      margin: 0 1.5rem;
    }

    .fy-zoom-slider-track {
      width: 100%;
      height: 2.25rem;
      align-items: center;
    }

    .fy-zoom-slider-track-bar {
      height: 0.5rem;
      width: 100%;
    }

    svg {
      align-self: center;
    }
  }

  svg {
    color: rgba(#fff, 0.25);
    cursor: pointer;
    transition: color 0.25s ease-in-out;

    &:hover {
      color: rgba(#fff, 0.5);
    }
  }

  .fy-zoom-slider-container {
    height: 100%;
  }

  .fy-zoom-slider-track {
    display: inline-flex;

    .fy-zoom-slider-track-bar {
      background-color: $secondary-darker;
      border-radius: 0.25rem;
      box-shadow: inset 0 5px 10px rgb(#000, 0.25);
      border-bottom: 1px solid rgba(#fff, 0.07);
    }
  }

  .fy-zoom-slider-thumb {
    color: rgba(#fff, 0.6);
    border: 1px solid rgba(#fff, 0.04);
    border-left: none;
    border-right: none;
    box-shadow: 0 5px 10px rgb(#000, 0.25);

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    background-color: $secondary;
    border-radius: 0.75rem;
    height: 2.25rem;
    width: 2.25rem;

    &:focus-visible {
      background-color: $secondary-light;
      outline: none;
    }
  }
}
