@import "scss/colours.scss";
@import "scss/site-refresh.scss";

.work-indicator-label {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.work-indicator {
  @include medium-svg;
  position: relative;

  .work-indicator-progress {
    text-align: center;
    position: absolute;
    font-size: 0.625rem;
    line-height: 0.625rem;
    height: 0.625rem;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    bottom: 0;
  }

  polyline {
    transform: translate(6px, 6px);
  }

  .work-indicator-circle-track {
    stroke: rgba(#fff, 0.15);
    stroke-width: 4px;
  }

  .work-indicator-circle-marker {
    transform-origin: 18px 18px;
    animation: work-indicator-spin 1.5s linear infinite;
  }

  @keyframes work-indicator-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
