@import "scss/colours.scss";

.fy-video {
  overflow: hidden;
  width: 100%;
  margin: 0;
  position: relative;
  background-color: $bg;
  border-radius: 0.5rem;
  border: $panel-inner-border 1px solid;
  .fy-spinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.fit-content {
    height: fit-content;
  }

  video, .video-overlay {
    border-radius: .5rem;
    width: 100%;
    height: 100%; 
  }

  video{
    object-fit: cover;
    &.object-fit-cover {object-fit: cover;}
    &.object-fit-contain {object-fit: contain;}
  }

  .video-overlay {
    background: linear-gradient(rgba($primary,0.5) 0%, rgba($secondary,0.75) 75%);
    cursor: pointer;
    padding: 2rem;
    position: absolute;
    top: 0px;

    h6, p {
      color: #fff;
      filter: drop-shadow(0 3px 3px rgba($primary-dark,.25));
      font-size: 1.5rem;
      text-align: center;
    }

    svg {
      filter: drop-shadow(0 3px 7px rgba($primary-dark,.45));
      position: absolute;
      width: 3rem;
      height: 3rem;
      inset: 50%;
      transform: translate(-50%, -50%);
    }

    .video-play-button {
      background-color: green;
      width: 4rem;
      height: 4rem;
      stroke: white;
    }
  }
};