@import "scss/colours.scss";
@import "scss/site-refresh.scss";
@import "scss/_breakpoint.scss";
@import "scss/breakpoints.scss";

.fy-camera-capture-modal {
	// background: red;
	width: 85vw;
	aspect-ratio: 1/1;
	max-height: 95vh;
	// height: 75vh;

	position: relative;

	.fy-camera-capture-close {
		@include video-tint;
		border: none;
		position: absolute;
		right: 1rem;
		top: 1rem;

		&:hover {
			background-color: rgba($secondary-very-dark, 0.45);
		}
	}

	.fy-camera-capture-slide {
		position: absolute;
		width: 100%;
		height: 100%;

		video {
			height: 100%;
		}
	}

	.fy-camera-capture-centered {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	.fy-camera-capture-display {
		// background: red;
		width: 100%;
		height: 100%;
		margin: auto;
	}

	.fy-camera-capture-controls {
		@include video-tint;
		position: absolute;
		bottom: 1rem;
		border-radius: 0.5rem;
		padding: 0.5rem 0.5rem 0.25rem;
		left: 0;
		right: 0;
		margin: auto;
		width: 50%;
		display: flex;
		justify-content: center;
		gap: 1rem;
		align-items: center;
		// justify-content: space-between;

		@include breakpoint($under992) {
			width: 75%;
		}
	}

	.fy-camera-capture-record-toggle {
		appearance: none;
		border: none;
		background: none;
		display: inline-flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		color: #fff;
		font-size: 0.75rem;

		// background: green;

		svg {
			display: block;
			height: 2rem;
			width: 2rem;
			margin-bottom: 0.25rem;

			&.fy-camera-capture-btn-record {
				circle {
					stroke: $primary;
				}
				rect {
					fill: $primary;
				}
				&:hover {
					circle {
						stroke: $primary-light;
					}
					rect {
						fill: $primary-light;
					}
				}
			}

			&.fy-camera-capture-btn-stop {
				circle {
					stroke: $secondary-lightest;
				}
				rect {
					fill: $secondary-lightest;
				}
				&:hover {
					circle {
						stroke: #fff;
					}
					rect {
						fill: #fff;
					}
				}
			}

			circle,
			rect {
				transition: all 0.25s ease-in-out;
			}

			circle {
				fill: none;
				stroke-width: 2px;
			}
		}
	}

	.fy-camera-capture-preview {
		width: 100%;
		position: absolute;
	}

	.fy-camera-capture-preview-actions {
		position: absolute;
		display: flex;
		top: 1rem;
		left: 1rem;
		gap: 1rem;

		@include breakpoint($under768) {
			justify-content: center;
			top: 4.5rem;
			left: 0;
			width: 100%;
		}
	}
}
