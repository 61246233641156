.mentions-section {
  padding-top: 5rem;
  height: 100%;

  .mentions-swiper {
    height: 100%;

    .swiper-pagination-bullet {
      background-color: #ffffff5b;
    }
    .swiper-pagination-bullet-active {
      background-color: #c8d8e9 !important;
    }

    .swiper {
      height: 100%;
    }

    .swiper-wrapper {
      height: 100%;
      padding-bottom: 50px;
    }

    .swiper-slide {
      height: 100%;
    }

    .swiper-card {
      padding: 2.6rem;
      border-radius: 0.5rem;
      position: relative;
      height: 100%;
      margin: 0;

      @media (max-width: 767px) {
        height: 500px;
      }

      &.bg-dark-solid {
        background-color: #343a40;
        color: white;
      }

      .mention-badge {
        position: absolute;
        top: 10px;
        left: 10px;
        background-color: rgba(0, 0, 0, 0.25);
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 0.9rem;
      }

      .mention-content {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .mention-logo {
          margin-bottom: 1rem;
          background-color: transparent !important;
          max-height: 200px;
          max-width: 200px;
        }

        .swiper-text {
          font-size: 1rem;
          line-height: 1.5;
        }
      }

      .swiper-text {
        font-size: 1rem;
        line-height: 1.5;
      }
    }

    &.mentions-swiper-main {
      width: 100%;
      height: 100%;
      margin: 0;
      margin-left: auto;
      margin-right: auto;
      user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      height: auto !important;
      border-radius: 0.5rem;
      overflow: hidden;
    }
  }
}
