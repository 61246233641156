@import "scss/colours.scss";

.fy-tint-spinner {
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($panel, .6);
  backdrop-filter: blur(2px);
  border-radius: .5rem;
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}