// @import "./scss/normalize.scss";
// @import-normalize; /* bring in normalize.css styles */
@import "./scss/nav.scss";
@import "./scss/colours.scss";
@import "./scss/site-refresh.scss";
// @import "./scss/CSSParticles.scss";

$light-text-color: rgba($white, 0.8);
$button-text-color: #666;

@font-face {
  font-family: "Source Sans 3";
  src: url(./fonts/Source_Sans_3.woff2) format("woff2");
  font-weight: 200 900;
  font-style: normal;
}

$body-font-family: "Source Sans 3", sans-serif;
$nav-font-family: "Source Sans 3", sans-serif;
$footer-font-family: "Source Sans 3", sans-serif;
$textarea-font-family: "Source Sans 3", sans-serif;
$headings-font-family: "Source Sans 3", sans-serif;

// Old design parents all elements with class "fakeyou-old"
// Refresh design parents all elements with class "fakeyou-refresh"
//
// The reason for this is that WebPack imports and packs all SCSS files, which
// makes it impossible to dynamically change styles.
//
// Once the rollout is complete, this outer wrapper class can be removed.

.fakeyou-refresh {
  body {
    font-family: $body-font-family;
    color: $white;
  }

  .bg-gradient {
    background: $bg !important;
  }

  // Style for user text input that overflows
  .overflow-fix {
    overflow-wrap: anywhere;
  }

  canvas#spectrogram {
    margin: 0.5em 0;
    transform: scaleY(-1);
    /* Spectrograms are flipped */
    height: 300px;
    width: 100%;
    cursor: pointer;
  }

  .btn:focus {
    outline: none;
    box-shadow: none;
  }

  #main {
    position: relative; // Footer attachment.
    min-height: calc(100vh - 66px);
  }

  .social {
    img {
      max-width: 70px;
      max-height: 70px;
      padding: 1em;
    }
  }

  // Tech logos need a hack to not have margin.
  .vocodes-grid-marginless {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  nav {
    font-family: $nav-font-family;

    .navbar {
      z-index: 2;
    }

    .navbar-dark .navbar-toggler {
      border: none;
    }

    .nav-link {
      font-size: 15px;
      font-weight: 500;
      padding-left: 16px;
      padding-right: 16px;
      color: $light-text-color;
      display: flex;
      align-items: center;
    }

    a:link.nav-link-red,
    a:visited.nav-link-red {
      color: #e66462 !important;
    }

    a:hover.nav-link-red,
    a:active.nav-link-red {
      color: #ea7a78 !important;
    }

    .navbar-brand {
      padding-top: 0;
      margin-bottom: 3px;
    }

    .dropdown-item {
      font-size: 15px;
      font-weight: 600;
      padding: 0.5rem 1rem;
      color: $white;

      &:hover {
        color: $white;
        background-color: $secondary;
      }

      &:focus {
        background-color: $secondary;
      }

      &:active {
        background-color: lighten($secondary, 10%);
        color: $white;
      }
    }

    .dropdown-menu {
      background-color: $panel;
      border-color: rgba($white, 0.12) !important;
      border: 1px solid;
      border-radius: 8px;
      box-shadow: 0px 5px 20px rgba($black, 0.3);
    }

    .nav-bg-dark {
      background-color: $bg;
    }

    .offcanvas {
      width: 100vw;
    }

    .nav-login {
      font-size: 15px;
      font-weight: 600;
      color: $white;
      text-decoration: none;
      transition: 0.2s;

      &:hover {
        color: rgba($white, 0.6);
        text-decoration: none;
      }
    }
  }

  a.top-bar-text:hover {
    color: $primary !important;
  }

  .top-bar {
    display: flex;
    align-items: center;
    background-color: $panel;
    margin-bottom: 5px;
    padding: 10px 0;
    border-bottom: 1px solid;
    border-color: rgba(255, 255, 255, 0.06);
  }

  .top-bar-text {
    color: rgba($white, 0.7);
    font-size: 14px;
    font-weight: 400;
    transition: 0.2s;
  }

  .dropdown-divider-white {
    border-top: 1px solid rgba($white, 0.15);
  }

  .button-group {
    padding: 1em 0;
  }

  #status {
    .info {
      color: #888888;
    }

    .warn {
      color: #aaaa00;
    }

    .error {
      color: #aa0000;
    }

    min-height: 1.5em;
  }

  .upload-box {
    padding: 0 0 1em;
  }

  .video-template-selector {
    margin: 1.5em 0 1.5em 0;
    text-align: center;

    .thumbnail {
      max-width: 220px;
      padding: 0em;
      margin: 5px;
      filter: brightness(70%) grayscale(1);
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
    }

    .selected {
      filter: brightness(100%) grayscale(0);
      box-shadow: 0px 0px 25px #209cee;
    }
  }

  .video-wrapper {
    text-align: center;
  }

  // #usage,
  // #updates,
  // #help-wanted,
  // #terms {
  //   h1,
  //   h2 {
  //     font-family: $headings-font-family;
  //   }

  //   p {
  //     text-align: justify;
  //   }

  //   li {
  //     font-size: 1.2em;
  //     text-align: justify;
  //     margin-bottom: 1em;
  //   }
  // }

  // .template-preview {
  //   text-align: center;
  // }

  // /* V2 (but probably defined elsewhere too) */

  .mascot {
    text-align: center;
    margin: auto;
  }

  .mascot img {
    max-width: 200px;
  }

  // .main-form {
  //   padding: 0 15px;
  // }

  .git-sha {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
  }

  // /* TAILWIND STYLE CLASSES */

  // .display-inline {
  //   display: inline;
  // }

  // .white-space-nowrap {
  //   white-space: nowrap;
  // }

  // .padding-bottom-1em {
  //   padding-bottom: 1em;
  // }

  /** RESPONSIVENESS **/

  /** Hide footer when using onscreen keyboard. */
  /* Actually, let's not do that because people filed bug reports.
  @media screen and (max-height: 550px) {
    footer {
      display: none;
    }
    nav {
      display: none;
    }
  }
  */

  #particles-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    pointer-events: none;

    canvas {
      pointer-events: none !important;
    }
  }

  #tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .to-top-button-off-screen {
    position: fixed;
    width: fit-content;
    white-space: nowrap;
    top: 5rem;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 3;

    box-shadow: 0px 5px 20px rgba($black, 0.3);
  }
}

@media screen and (max-height: 740px) {
  .fakeyou-refresh {
    footer p {
      display: inline;
    }

    footer p:after {
      content: " "; // Not sure why the space disappears when inline.
    }

    .social {
      img {
        max-width: 50px;
        max-height: 50px;
        padding: 0.2em;
      }
    }
  }
}

// // Prevent Android devices from horizontal scrolling
// // Mine is 412x846.
// @media screen and (max-width: 412px) {
//   #root {
//     max-width: 380px;
//     margin: auto;
//   }
// }

@media (min-width: 992px) {
  .fakeyou-refresh {
    nav {
      .nav-bg-dark {
        background-color: $bg !important;
      }

      // hover nav dropdown on desktop
      &.navbar {
        .nav-item {
          &:hover {
            .nav-link {
              color: $white;
            }

            .dropdown-menu {
              transition: 0.2s;
              opacity: 1;
              visibility: visible;
              top: 100%;
              transform: rotateX(0deg);
            }
          }

          .dropdown-menu {
            display: block;
            opacity: 0;
            visibility: hidden;
            transition: 0.2s;
            margin-top: 0;
          }
        }
      }
    }

    .top-bar-text {
      font-size: 12px;
    }
  }
}
