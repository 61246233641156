.fy-profile-dropdown {
  position: relative;
  display: inline-block;
  transition: 0.15s all;
}

.fy-profile-pic {
  cursor: pointer;
}

.fy-profile-dropdown-menu {
  display: block;
  right: 0;
  left: auto;
  margin-top: 6px;
}
