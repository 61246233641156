@import "scss/colours.scss";

// .fy-entity-type-audio { background-color: teal; }
// .fy-entity-type-image { background-color: ultramarine; }
// .fy-entity-type-video { background-color: purple; }
// .fy-entity-type-bvh { background-color: pink; }
// .fy-entity-type-fbx { background-color: lime; }
// .fy-entity-type-glb { background-color: lime; }
// .fy-entity-type-gltf { background-color: lime; }

.fy-entity-type-audio {
  background-color: map-get($badge-colors, "teal");
}
.fy-entity-type-image {
  background-color: map-get($badge-colors, "ultramarine");
}
.fy-entity-type-video {
  background-color: map-get($badge-colors, "purple");
}
.fy-entity-type-bvh {
  background-color: map-get($badge-colors, "pink");
}
.fy-entity-type-fbx {
  background-color: map-get($badge-colors, "lime");
}
.fy-entity-type-glb {
  background-color: map-get($badge-colors, "lime");
}
.fy-entity-type-gltf {
  background-color: map-get($badge-colors, "lime");
}
.fy-entity-type-scene_ron {
  background-color: map-get($badge-colors, "turquoise");
}
