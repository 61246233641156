@import "scss/colours.scss";
@import "scss/site-refresh.scss";

$primary-transparent: rgba($primary, 0.75);

.fy-inference-jobs-list {
  overflow: hidden;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    margin-bottom: 1rem;

    h3 {
      height: 1em;
      margin-bottom: 0;
      // display: inline;
      // background-color: green;
    }
  }
}

.fy-inference-jobs-content {
  // background-color: red;
  min-height: 40vh;
  &.fy-inference-jobs-scroll-list {
    max-height: 60vh;
    overlow-y: scroll;
    overflow-x: hidden;
    border-radius: 0.5rem;
  }
}

.fy-clear-jobs-input {
  position: relative;
  height: 100%;
  // width: 3rem;
  right: 0;

  .fy-clear-jobs-frame {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    // top: 50%;
    // transform: translateY(-50%);
  }
}

.fy-inference-jobs-list-grid {
  display: grid;
  grid-template-columns: min-content fit-content(100px) minmax(0, 1fr) minmax(
      min-content,
      1fr
    );
  // grid-template-rows: 4rem;
  gap: 1rem 0;

  .work-indicator {
    margin-right: -0.5rem; // offset for svg gap
  }

  h6 {
    color: #fff;
    line-height: 1em;
    height: 1.25em;
    margin-bottom: 0;
    white-space: nowrap;
  }

  .fy-inference-job-hover-on,
  .fy-inference-job-hover-off {
    padding: 0.75rem;

    // &:first-of-type { }
  }

  .fy-inference-job-success {
    cursor: pointer;

    button {
      height: 2.25rem;
      width: 2.25rem;
      margin: auto 0 auto 1rem;
    }

    &.fy-inference-job-hover-on {
      background-color: $panel-inner;
    }
  }

  .fy-inference-job-indicator {
    border-radius: 0.5rem 0 0 0.5rem;
    // margin-left: -0.5rem;
    // padding-right: 0;

    .fy-inference-job-indicator-container {
      // background-color: red;
      position: relative;
      height: fit-content;
    }

    .fy-inference-job-progress {
      text-align: center;
      position: absolute;
      // background-color: green;
      font-size: 0.625rem;
      line-height: 0.625rem;
      height: 0.625rem;
      // height: 100%;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      bottom: 0;
      // margin: auto;
      // display: inline-block;
      // vertical-align: middle;
    }
  }
  .fy-inference-job-action-frame {
    border-radius: 0 0.5rem 0.5rem 0;
  }

  .fy-inference-job-subtitle,
  .fy-inference-job-subtitle-success,
  .job-info-label {
    font-size: 0.875rem;
    font-weight: 400;
    white-space: nowrap;
    height: 1.125em;
    line-height: 1em;
  }

  .fy-inference-job-subtitle,
  .job-info-label {
    opacity: 0.6;
  }

  .fy-inference-job-subtitle,
  .fy-inference-job-subtitle-success {
    display: block;
  }

  .fy-inference-job-subtitle-success {
    color: $primary-transparent;
  }

  .job-info-label {
    overflow: hidden;
    text-overflow: ellipsis;
    // height: 1.125em;
    // line-height: 1em;
  }

  .job-info-value {
    // background: rgba(#0f0, .1);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // height: 1em;
    line-height: 1.25em;
  }

  .fy-inference-job-action-frame {
    // margin-right: -0.5rem;
    position: relative;
    padding: 0;
    // width: fit-content;
    // background: rgba(#0f0, .1);

    .fy-inference-job-action,
    .fy-inference-job-action-success {
      @include medium-svg;

      cursor: pointer;
      margin: auto;
      white-space: nowrap;
      border-radius: 0.5rem;
      margin: auto 0 auto 1rem;
    }

    .job-status-complete-success {
      &:hover {
        background-color: $panel-inner;
      }
    }

    .fy-inference-job-action-success {
      background-color: $primary-transparent;
      &:hover {
        background-color: lighten($primary, 5%);
      }
    }

    .fy-inference-job-action,
    .job-stop-confirm,
    .job-stop-cancel {
      cursor: pointer;
      background-color: $secondary;
      &:hover {
        background-color: $secondary-light;
      }
    }
    .fy-slide-wrapper {
      justify-content: flex-end;
      display: flex;
      align-items: center;
      padding-right: 0.5rem;
      white-space: nowrap;
    }

    .job-stop-confirm,
    .job-stop-cancel {
      border-radius: 0.5rem;
      margin-left: 0.5rem;
      padding: 0.625rem 1rem;
      line-height: 1em;
      // height: 2.25rem;
    }
  }

  .fy-inference-job-preview {
    // background-color: red;
    // grid-column-start: 1;
    // grid-column-end: five;

    .fy-inference-job-preview-thumb {
      // background-color: blue;
      // margin: 3rem 0;
      height: 3.25rem;
      width: fit-content;
      overflow: hidden;
      aspect-ratio: 4/3;
      position: relative;

      .fy-inference-job-preview-static,
      .fy-inference-job-preview-gif {
        position: absolute;
        border-radius: 0.5rem;
        width: auto;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.fy-job-queue-ticker {
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  // padding:1rem;
  background-color: rgba(0, 0, 0, 0.25);

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }

  h6 {
    display: inline;
    margin-bottom: 0;
    opacity: 0.66;
    padding: 1rem;
    height: 1em;
  }

  @keyframes tictictic {
    00.0% {
      transform: rotate(0deg);
    }
    04.2% {
      transform: rotate(15deg);
    }
    08.3% {
      transform: rotate(30deg);
    }
    12.5% {
      transform: rotate(45deg);
    }
    16.7% {
      transform: rotate(60deg);
    }
    20.8% {
      transform: rotate(75deg);
    }
    25.0% {
      transform: rotate(90deg);
    }
    29.2% {
      transform: rotate(105deg);
    }
    33.3% {
      transform: rotate(120deg);
    }
    37.5% {
      transform: rotate(135deg);
    }
    41.7% {
      transform: rotate(150deg);
    }
    45.8% {
      transform: rotate(165deg);
    }
    50.0% {
      transform: rotate(180deg);
    }
    54.2% {
      transform: rotate(195deg);
    }
    58.3% {
      transform: rotate(210deg);
    }
    62.5% {
      transform: rotate(225deg);
    }
    66.7% {
      transform: rotate(240deg);
    }
    70.8% {
      transform: rotate(255deg);
    }
    75.0% {
      transform: rotate(270deg);
    }
    79.2% {
      transform: rotate(285deg);
    }
    83.3% {
      transform: rotate(300deg);
    }
    87.5% {
      transform: rotate(315deg);
    }
    91.7% {
      transform: rotate(330deg);
    }
    95.8% {
      transform: rotate(345deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .job-cta-clock {
    height: 2.625rem;
    width: 2.625rem;
    fill: none;
    margin-left: 0.25rem;
    animation: tictictic 15s cubic-bezier(1, 0.14, 0.27, 1.51) infinite;

    .clock-circle {
      fill: $primary;
    }
    .mask-circle {
      fill: #fff;
    }

    line {
      stroke-width: 3;
      fill: black;
      stroke: black;
      stroke-linecap: round;
    }
  }

  .fy-job-queue-grid {
    display: grid;
    padding: 0 1rem 1rem;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    gap: 0.5rem;

    * {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      div {
        margin: auto auto auto 0;
        max-width: 5rem;
        // background-color: greenyellow;
        // white-space: nowrap;
        font-size: 0.75rem;
        opacity: 0.5;
        line-height: 1.25em;
      }
    }
  }

  .cta-memembership {
    border-width: 1px;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-top: none;
    border-right: none;
    overflow: hidden;
    transition: background-color 0.5s cubic-bezier(0.45, 0, 0.55, 1);
    background-color: rgba($primary, 0.45);
    border-radius: 0 0.5rem 0 0.5rem;
    line-height: 1em;

    &:hover {
      background-color: rgba($primary, 0.25);
    }

    .cta-membership-gradient {
      background: linear-gradient(
        90deg,
        rgba($primary, 0) 0%,
        rgba($primary, 0.85) 100%
      );
      padding: 0.5rem;
      position: relative;
      display: flex;
      color: rgba(#fff, 0.8);
      align-items: center;

      .job-cta-message {
        line-height: 1.25em;
        margin: 0 0.5rem 0 0.75rem;
        &:hover {
          color: rgba(#fff, 0.8);
        }
        span {
          color: rgba(#fff, 0.9);
          font-weight: bolder;
          text-decoration: underline;
          text-shadow: $primary 0 0 6px;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}

.fy-slide-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
