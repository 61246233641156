.fy-textarea {
  margin-bottom: 1rem;

  &:only-child,
  &:last-child {
    margin-bottom: 0;
  }

  .no-resize {
    resize: none;
  }
}

.d-flex:not(.flex-column) > .fy-textarea {
  margin-bottom: 0;
  margin-right: 0.5rem;

  &:only-child,
  &:last-child {
    margin-bottom: 0;
  }
}

.gap-1 > .fy-textarea,
.gap-2 > .fy-textarea,
.gap-3 > .fy-textarea,
.gap-4 > .fy-textarea,
.gap-5 > .fy-textarea {
  margin-bottom: 0;
  margin-right: 0;
}
