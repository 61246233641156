@import "scss/colours.scss";
@import "scss/site-refresh.scss";
@import "scss/_breakpoint.scss";
@import "scss/breakpoints.scss";

.fy-media-browser-tools {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;

  .pagination {
    margin: auto 0 auto auto;
  }

  .fy-temp-select {
    margin-bottom: 0;
  }

  @include breakpoint($under768) {
    flex-wrap: wrap;

    .fy-media-browser-tools {
      order: 3;
    }

    svg {
      order: 2;
    }
  }
}

.fy-media-browser-header {
  h3 {
    margin-bottom: 0;
  }

  > * {
    margin-bottom: 1rem;
  }

  .fy-input {
    margin-bottom: 0;
    flex-grow: 1;
  }

  @include close-parent;
}

.fy-media-browser-list {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  height: 70vh;

  @media (max-width: 768px) {
    height: calc(100vh - 160px);
  }
}

.fy-media-browser-footer {
  margin-top: 1rem;
}
