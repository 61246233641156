.fy-input {
  margin-bottom: 1rem;

  &:only-child,
  &:last-child {
    margin-bottom: 0;
  }
}

.d-flex:not(.flex-column) > .fy-input {
  margin-bottom: 0;
  margin-right: 0.5rem;

  &:only-child,
  &:last-child {
    margin-bottom: 0;
  }
}

//Allow gap classes in parent to override margins
.gap-1 > .fy-input,
.gap-2 > .fy-input,
.gap-3 > .fy-input,
.gap-4 > .fy-input,
.gap-5 > .fy-input {
  margin-bottom: 0;
  margin-right: 0;
}
