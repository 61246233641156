@import "scss/colours.scss";

.fy-login-modal {
  // overflow: hidden;
  // background: green;
  .fy-modal-page {
    top: 0;
    left: 0;
    // position: absolute;
    width: 100%;
    // background: red;
  }
  position: relative;
  > div {
    position: relative;
    // height: 100%;
    width: 100%;
    // background: red;
  }
  header {
    // display: flex;
    > div {
      display: flex;
      justify-content: space-between;
    }
    .login-modal-title-row {
      // background: red;
      width: 100%;
    }
    .login-modal-subtitle-row {
      // background: green;
      width: 100%;
    }

    .btn-close {
      filter: brightness(0) invert(1);
    }
  }
  h2 {
    margin-bottom: 0;
  }
  .login-modal-subtitle {
    opacity: 0.65;
    display: block;
    margin-bottom: 1rem;
  }
  .login-modal-login-link {
    cursor: pointer;
    color: $primary;
    transition: opacity 0.25s ease-in-out;
  }

  .error-message {
    color: $primary;
    margin-bottom: 1rem;
    text-align: center;
  }
}

.modal-spinner {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.animating-modal-page {
  .login-modal-login-link {
    cursor: pointer;
    opacity: 0.33;
    // color: $secondary;
  }
}
