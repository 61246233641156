@import "scss/colours.scss";

.fy-segment-buttons {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 1px;
  padding: 0;

  li {
    background-color: $secondary;
    border-radius: 0.125rem;
    cursor: pointer;
    display: inline-flex;
    flex: 1;
    flex-direction: column;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    min-height: 46.5px;
    padding: 0.75rem;
    transition: 0.1s background-color ease-in-out;
    font-size: 14px;

    &:hover {
      background-color: $secondary-light !important;
    }
    &:first-of-type {
      border-radius: 0.5rem 0.125rem 0.125rem 0.5rem;
    }
    &:last-of-type {
      border-radius: 0.125rem 0.5rem 0.5rem 0.125rem;
    }
    &.fy-selected-segment {
      background-color: $secondary-lighter;

      &:hover {
        background-color: $secondary-very-light !important;
      }

      &.fy-highlighted-segment {
        background-color: rgba($primary, 80%);
        color: $white;

        &:hover {
          background-color: rgba($primary, 80%) !important;
        }
      }
    }

    p {
      font-size: 12px;
      opacity: 0.8;
      margin: 0;
      padding: 0;
    }

    .fy-segment-button-icon {
      font-size: 24px;
      margin-bottom: 0.5rem;
      color: lighten($secondary-lightest, 60%);
    }

    &.fy-disabled-segment {
      cursor: not-allowed;
      opacity: 0.5;
      background-color: darken($panel, 2%);
      pointer-events: none;

      &:hover {
        background-color: darken($panel, 2%) !important;
      }

      .fy-segment-button-icon {
        color: darken($secondary, 30%);
      }
    }
  }
}
