@import "scss/colours.scss";
@import "scss/site-refresh.scss";

.fy-camera-input {
	// background: red;
	border: lighten($panel, 6%) solid 2px;
	// height: 100%;
	width: 100%;
	aspect-ratio: 1;
	position: relative;
	border-radius: 0.5rem;
	overflow: hidden;
	cursor: pointer;

	&.fy-camera-input-unsuported {
		cursor: not-allowed;
	}

	.fy-camera-input-preview {
		// background: red;
		position: absolute;
		width: 100%;
		height: 100%;
		margin-bottom: 0;
		top: 0;
		// margin: ;
		padding: 0;

		video {
			height: 100%;
		}

		.fy-camera-input-float-btn {
			background-color: rgba($primary, 0.85);
			backdrop-filter: blur(5px);
			position: absolute;
			top: 0.5rem;
			margin: auto;
			left: 0.5rem;
			right: 0.5rem;
		}
	}

	.fy-camera-input-toggle-container {
		@include video-controls;
		border-radius: 0.5rem;
		padding: 0.5rem 0.5rem 0.25rem;
		left: 0;
		right: 0;
		margin: auto;
		bottom: 0.5rem;
		position: absolute;
	}
}
