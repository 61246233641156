@import "scss/colours.scss";

.fy-number-slider {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  .fy-number-slider-range {
    flex-grow: 1;
  }

  .fy-number-slider-value {
    text-align: center;
    width: fit-content;
    padding: 6px;
    border-radius: 8px;
    background-color: $secondary;
    border-width: 0px;
    border-color: none;
    color: rgba(#fff, 0.85);
    font-weight: 500;
    flex-shrink: 0;
  }

  .fy-number-slider-track {
    height: 6px;
    margin: 0 0 0 0.6875rem; // margin half width of thumb, so that thumb does not overlap margins at minimum position
    background-color: #4f4f66;
    border-radius: 0.25rem;
  }

  .fy-number-slider-thumb {
    height: 16px;
    width: 16px;
    background-color: #e66462;
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgba(#000, 0.15);
    &:hover {
      background-color: #fff;
    }
  }
}
