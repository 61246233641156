@import "scss/colours.scss";

.fy-nav-item {
  position: relative;
  padding: 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  color: rgba($white, 0.8) !important;
  transition: 0.15s all;

  a {
    color: rgba($white, 0.8) !important;
    font-size: 15px;
    font-weight: 500 !important;
    transition: 0.15s all;
  }

  &:hover {
    a {
      color: $white !important;
    }
  }
}

.fy-nav-item:hover .fy-dropdown-menu {
  display: block;
}
