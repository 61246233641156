@import "scss/colours.scss";

.select__control {
  &.select {
    height: 48px;
    border-width: 2px;
    padding-left: 0.15rem;
    padding-right: 0.15rem;
    background-color: $input-dark;
    border-radius: 0.5rem;
    border-color: $input-dark !important;
    box-shadow: none;
    cursor: pointer;

    &.no-padding {
      padding-left: 0.5rem;
    }

    &:hover {
      background-color: lighten($input-dark, 5%);
      border-color: lighten($input-dark, 5%) !important;
    }

    &.focused {
      border-color: $primary !important;
    }

    &.with-icon {
      padding-left: 2rem;
    }

    &.rounded-full {
      border-radius: 50px;
    }
  }

  .select-option {
    background-color: $input-dark;
    cursor: pointer;
    padding: 0.5rem 1rem;

    &:hover {
      background-color: lighten($input-dark, 8%);
    }
  }

  .select-input,
  .select-value {
    color: $white;
  }

  .select-container {
    background: $input-dark;
    margin-top: 6px;
    padding: 0;
    border-radius: 0.5rem;
    z-index: 3;
  }

  .select-separator {
    width: 1px;
    opacity: 0.3;
  }

  .select-placeholder {
    color: rgba($white, 0.5) !important;
  }
}

.select-small {
  font-size: 14px !important;
}
