@import "scss/colours.scss";

.favorite-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(lighten($secondary, 5%), 0.9);
  color: rgba($white, 0.7);
  border: none;
  padding: 0;
  font: inherit;
  font-size: 15px !important;
  cursor: pointer;
  outline: inherit;
  transition: 0.2s;
  padding: 3px 8px;
  min-width: 52px;
  max-height: 27px;
  border-radius: 0.5rem;
  overflow: hidden;

  &.shadow {
    box-shadow: 0 0 12px rgba($bg, 0.4) !important;

    &:hover {
      box-shadow: 0 0 12px rgba($bg, 0.4) !important;
    }
  }

  &:hover {
    background: rgba(lighten($secondary, 10%), 0.9);
  }

  &:hover .favorite-text {
    opacity: 1;
  }

  &:hover .icon-default {
    color: $white;
    opacity: 1;
  }

  &.toggled {
    background-color: darken(rgba(map-get($badge-colors, "green"), 1), 4%);

    &:hover {
      background-color: darken(rgba(map-get($badge-colors, "green"), 1), 0%);
    }
  }

  &.large {
    padding: 6px 10px;
    max-height: 33px;
  }
}

.favorite-button .icon-default {
  color: $white;
  opacity: 0.7;
  transition: 0.2s;
}

.favorite-button .icon-toggled {
  color: $white;
  animation: pulse 0.3s ease;
}

.favorite-button .icon-toggled:hover {
  color: $white;
}

.favorite-text {
  color: $white;
  font-size: 14px;
  font-weight: 600 !important;
  height: 100%;
  opacity: 0.9;
  transition: 0.2s;
  // flex-grow: 1;
  position: relative;
  // width: 1.5rem;
  // display: inline-block;
  // width: 100%;
  // height: max-content;

  .favorite-text-wrapper {
    position: relative;
  }

  span {
    display: block;
    top: 0;
    // height: fit-content;
    // width: 2.5rem;
    background-color: green;
    position: absolute;
  }
}

@keyframes workdots {
  0% {
    opacity: 0.33;
  }
  33% {
    opacity: 0.66;
  }
  66% {
    opacity: 0.66;
  }
  100% {
    opacity: 0.33;
  }
}

.fy-workdots {
  width: 1rem;
  height: 1rem;
  fill: #fff;

  circle {
    animation: 0.66s infinite workdots;
    animation-timing-function: ease-in-out;
  }
  :nth-child(1) {
    animation-delay: 0;
  }
  :nth-child(2) {
    animation-delay: 0.22s;
  }
  :nth-child(3) {
    animation-delay: 0.44s;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
