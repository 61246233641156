@import "scss/colours.scss";

.nav-tabs {
  border-bottom: 2px solid #ffffff15;
  flex-wrap: nowrap;
  overflow-x: auto !important;
  overflow-y: hidden !important;
  -webkit-overflow-scrolling: touch;

  @media screen and (max-width: 768px) {
    width: 100vw;
  }

  .nav-link {
    border: none;
    padding: 1rem 1.4rem;
    border-bottom: 4px solid transparent;
    color: rgba($white, 0.5);
    font-weight: 500;
    white-space: nowrap;
    transition: 0.2s;
    cursor: pointer;

    &:focus {
      border-bottom: 4px solid transparent;
    }

    &:hover {
      color: rgba($white, 0.75);
      border-bottom: 4px solid rgba($white, 0.75);
    }

    &:focus:hover {
      color: rgba($white, 0.75);
      border-bottom: 4px solid rgba($white, 0.75);
    }

    &.active {
      color: $white !important;
      background-color: transparent;
      border-bottom: 4px solid $primary !important;
    }

    &.disabled {
      color: rgba($white, 0.5) !important;
      pointer-events: none;
    }

    .number-count {
      display: flex;
      align-items: center;
      padding: 0px 8px;
      margin: 0;
      border-radius: 0.25rem;
      background-color: $secondary;
      font-size: 14px;
      font-weight: 500;
      color: rgba($white, 0.75) !important;
    }
  }
}
