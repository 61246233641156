@import "scss/colours.scss";

.fy-badge {
  display: flex;
  align-items: center;
  font-weight: 600;
  border-radius: 0.5rem;
  font-size: 13px;
  transition: 0.2s;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 10px;
  padding: 4px 8px;
  color: $white;
  white-space: nowrap;

  &.shadow {
    box-shadow: 0 0 12px rgba($bg, 0.4);
  }

  &.badge-small {
    font-size: 11px;
    padding: 2px 6px;
    border-radius: 0.3rem;
  }

  &.icon-badge {
    padding-left: 1rem;
    padding-right: 1rem;

    svg {
      color: #fff;
    }
  }
}

@each $color, $value in $badge-colors {
  .badge-#{$color} {
    background-color: $value;
    color: contrast($value) !important;
  }
}
