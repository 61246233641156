@import "scss/colours.scss";

.card {
  background-color: $panel-inner;
  border: 2px solid $panel-inner-border;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: 0.2s;
  position: relative;

  .card-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    transition: all 0.2s;

    &:hover {
      cursor: pointer;
      transform: scale(1.04);
    }

    &.card-bg-hover-img {
      transform: scale(1.05);
    }
  }

  .card-img {
    width: 100%;
    transition: 0.4s ease;
    object-fit: cover;
    object-position: center;
    min-height: 300px;
    max-height: 500px;
    background-color: #57577d;

    .card-img-icon {
      font-size: 12rem;
      color: lighten(#57577d, 15%);
    }
  }

  .card-video {
    width: 100%;
    transition: 0.4s ease;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 16/9;
  }

  .card-img-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .card-video-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 1rem;
  }

  .card-video-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: rgba($white, 0.5);
    transition: 0.2s;
  }

  .card-img-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40%;
    background: linear-gradient(180deg, transparent, darken($bg, 5%));
  }

  .card-img-overlay-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    color: $white;
    text-shadow: 0 0 4px rgba(#000, 0.75);
  }

  .card-featured-badge {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    font-size: 13px;
    font-weight: 600;
    background-color: $primary;
    border-radius: 0 0 6px 0;
    padding: 2px 8px 2px 4px;
    box-shadow: 0 0 12px rgba(#000, 0.3);

    svg {
      font-size: 11px;
      margin-bottom: 1px;
    }
  }

  &.card-clickable {
    &:hover {
      background-color: lighten($panel-inner, 2%);
      cursor: pointer;
      border: 2px solid lighten($panel-inner-border, 15%);
    }

    &:hover > .card-img {
      transform: scale(1.05);
    }

    &:hover > .card-video {
      transform: scale(1.05);
    }

    &:hover .card-video-play {
      color: rgba($white, 0.7);
    }

    &.card-hover-border-red {
      &:hover {
        border: 2px solid $primary;
      }
    }

    &.bottom-text-hover {
      background-color: lighten($panel-inner, 2%);
      border: 2px solid $primary;
    }
  }

  &.card-featured {
    border: 2px solid $primary;

    &:hover {
      cursor: pointer;
      border: 2px solid lighten($primary, 6%);
    }
  }

  h6 {
    //2 lines of text then ellipsis
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .fy-card-download-badge {
    margin: 0 0 0 auto;
  }
}

.card-bottom-text {
  padding-top: 0.75rem;
  cursor: pointer;
}

.centered-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
