@import "scss/colours.scss";

.search-bar-container {
  display: flex;
  justify-content: center;
  width: 580px;
}

.search-field-group {
  position: relative;
  width: 100%;
}

.search-field {
  .form-control {
    padding-top: 7.25px !important;
    padding-bottom: 7.25px !important;
    border-radius: 0.5rem 0 0 0.5rem !important;
    background-color: $input-dark !important;
    position: relative;
    z-index: 3;

    &::placeholder {
      color: rgba($white, 0.3) !important;
    }

    &.landing-search {
      background-color: rgba($input-dark, 0.5) !important;
    }
  }
}

.search-results-dropdown {
  position: absolute;
  border-color: lighten($panel-inner, 6%) !important;
  border: 1px solid;
  top: 46px;
  width: 100%;
  z-index: 4;
  border-radius: 0.5rem;
  overflow: hidden;

  .search-results-dropdown-item {
    background-color: $panel-inner;
    border-bottom: 1px solid lighten($panel-inner, 6%);
    transition: 0.05s;

    &:hover {
      background-color: lighten($panel-inner, 6%);
      cursor: pointer;
    }
  }

  .view-more {
    display: flex;
    justify-content: center;
    color: $primary;
    font-size: 15px;
    font-weight: 500;
    border-bottom: 0px;
  }
}

.search-bar-button {
  border-radius: 0 0.5rem 0.5rem 0 !important;
  border-color: lighten($secondary, 5%) !important;
  background-color: lighten($secondary, 5%) !important;

  &:hover {
    border-color: lighten($secondary, 10%) !important;
    background-color: lighten($secondary, 10%) !important;
  }
}

.no-results {
  text-align: center;
  color: rgba($white, 0.5);
  font-size: 15px;

  &:hover {
    background-color: $panel-inner !important;
    cursor: default !important;
  }
}

.loading-results {
  &:hover {
    background-color: $panel-inner !important;
    cursor: default !important;
  }
}

@media (max-width: 1200px) {
  .search-bar-container {
    width: 400px;
  }
}

@media (max-width: 992px) {
  .search-bar-container {
    width: 100%;
  }

  .search-field {
    .form-control {
      border-radius: 0.5rem !important;
    }
  }
}
