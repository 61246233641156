@import "scss/colours.scss";

.fy-cover-img-empty {
  width: 100%;
}

.fy-cover-img-input {
  // background-color: red;
  position: relative;
  height: 0;
  padding-bottom: 100%;

  .fy-cover-control {
    position: absolute;
  }

  .upload-control-btn {
    background-color: rgba($secondary, 0.8);
    backdrop-filter: blur(2px);
    border: none;
    margin: auto auto 1rem auto;
  }

  .weight-initial-cover-img {
    background-size: cover;
    background-position: center;
    border-radius: 0.5rem;
    position: absolute;
    // display: flex;
    overflow: hidden;
    height: 100%;
    width: 100%;
    // padding-bottom: 100%;

    div {
      // background-color: red;
      display: flex;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}
