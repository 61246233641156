@import "scss/colours.scss";

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(darken($panel, 8%), 0.8) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.modal {
  z-index: 1050;
  display: block;
}

.modal-dialog {
  @media (max-width: 992px) {
    margin: 0;
    max-width: 100%;
    height: 100%;
    width: 100vw;
    height: 100vh;
  }
}

.modal-body {
  padding: 0;
  overflow-y: auto;
  max-height: calc(100vh - 4rem);
}

.modal-width-auto {
  max-width: fit-content;
  margin: auto;
}

.modal-width-large {
  max-width: 1200px;
}

.modal-content {
  flex: 0 1 auto;
  min-width: 0;
  background-color: $panel;
  border-color: lighten($panel, 8%) !important;
  border: 1px solid;
  border-radius: 0.5rem;
  box-shadow: 0px 9px 33px 1px rgba(19, 19, 27, 0.8);
  -webkit-box-shadow: 0px 9px 33px 1px rgba(19, 19, 27, 0.8);
  -moz-box-shadow: 0px 9px 33px 1px rgba(19, 19, 27, 0.8);
  max-height: 100%;
  overflow: hidden;
}

.modal-header {
  border-bottom: none;
}

.modal-header .btn-close {
  filter: brightness(0) invert(1);
}

.modal-footer {
  border-top: none;
}

@media screen and (max-width: 992px) {
  .modal-dialog-mobile-fullscreen {
    margin: 0;
    max-width: 100%;
    max-height: 100%;
    width: 100vw;
    height: 100vh;

    .modal-content {
      height: 100%;
      width: 100%;
      border-radius: 0; // Remove border radius for fullscreen look
      overflow-y: auto;
      display: flex;
      flex-direction: column;

      .modal-body {
        flex-grow: 1;
        overflow-y: auto;
      }
    }
  }
}
