@import "scss/_breakpoint.scss";
@import "scss/breakpoints.scss";
@import "scss/colours.scss";

.fy-modal-layer {
  // position: absolute;
  position: fixed;
  background: rgba(#000, 0.5);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2000;

  display: flex;
  justify-content: center;
  align-items: center;

  .fy-modal-body-wide {
    max-width: 1140px;

    @include breakpoint($under1200) {
      max-width: 800px;
    }
  }

  .fy-modal-body-narrow {
    max-width: 50vw;
  }

  .fy-modal-body-small {
    max-width: 600px;
  }

  .fy-modal-body-wide,
  .fy-modal-body-narrow,
  .fy-modal-body-small {
    width: 100%;
    height: fit-content;
  }

  .fy-modal-body-wide,
  .fy-modal-body-narrow,
  .fy-modal-body-small,
  .fy-modal-body-square {
    overflow: hidden;
    background-color: $panel;
    border-color: lighten($panel, 8%) !important;
    border: 1px solid;
    border-radius: 0.5rem;
    box-shadow: 0px 9px 33px 1px rgba(19, 19, 27, 0.8);
    -webkit-box-shadow: 0px 9px 33px 1px rgba(19, 19, 27, 0.8);
    -moz-box-shadow: 0px 9px 33px 1px rgba(19, 19, 27, 0.8);
  }

  .fy-modal-body-wide,
  .fy-modal-body-narrow {
    padding: 1rem;

    @include breakpoint($under992) {
      max-width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }

  .fy-modal-body-small {
    padding: 1.4rem;

    @include breakpoint($under992) {
      max-width: 100%;
      height: 100%;
      border-radius: 0;
      padding: 1rem;
    }
  }

  .fy-modal-no-padding {
    padding: 0;
  }

  .fy-modal-scrollable {
    overflow-y: auto;
  }
}
