@mixin rtl($which, $step) {
  &:nth-child($which) { animation-delay: $step;}
}


.fy-spinner {
	stroke: white;
	stroke-width: 2px;

	polyline {
		transform-origin: 16px 16px;
		animation: feder 1s linear infinite;
		stroke-dasharray: 6;

		&:nth-child(2) { animation-delay: .0833s;}
		&:nth-child(3) { animation-delay: .1667s;}
		&:nth-child(4) { animation-delay: .25s;}
		&:nth-child(5) { animation-delay: .33s;}
		&:nth-child(6) { animation-delay: .4167s;}
		&:nth-child(7) { animation-delay: .5s;}
		&:nth-child(8) { animation-delay: .5833s;}
		&:nth-child(9) { animation-delay: .66s;}
		&:nth-child(10) { animation-delay: .75s;}
		&:nth-child(11) { animation-delay: .833s;}
		&:nth-child(12) { animation-delay: .9167s;}
	}
}

@keyframes feder {
  0% {
  	stroke-dashoffset: 0;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  35% {
    opacity: .25;
  }
  100% {
  	stroke-dashoffset: 6;
    opacity: 0;
  }
}

