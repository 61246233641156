/*
styles with important tags:
.container
*/

// Old design parents all elements with class "fakeyou-old"
// Refresh design parents all elements with class "fakeyou-refresh"
//
// The reason for this is that WebPack imports and packs all SCSS files, which
// makes it impossible to dynamically change styles.
//
// Once the rollout is complete, this outer wrapper class can be removed.

@mixin panel-no-border-color {
  background-color: $panel;
  border: 1px solid;
  // border-left: none;
  // border-right: none;
  border-radius: 0px;
  box-shadow:
    0 0 0 1px rgba(0 0 0 / 16%),
    0 70px 65px rgb(0 0 0 / 8%),
    0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 5%),
    0 10px 8px rgb(0 0 0 / 3%),
    0 4px 4px rgb(0 0 0 / 2%),
    0 2px 2px rgb(0 0 0 / 1%) !important;
}

@mixin panel {
  border-color: rgba(255, 255, 255, 0.04) !important;
  @include panel-no-border-color;
}

@mixin medium-svg {
  height: 2.25rem;
  width: 2.25rem;
  fill: none;

  circle,
  line,
  polyline {
    stroke-width: 3px;
    stroke-linecap: round;
    stroke: #fff;
  }
}

@mixin close-parent {
  .icon-close-button {
    cursor: pointer;
    height: 1.5rem;
    opacity: 0.33;

    &:hover {
      opacity: 1;
    }
  }
}

@mixin video-tint {
  background-color: rgba($secondary-very-dark, 0.5);
  backdrop-filter: blur(5px);
}

@mixin video-controls {
  @include video-tint;
  position: absolute;
  bottom: 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem 0.25rem;
  left: 0;
  right: 0;
  margin: auto;
  width: 50%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}

.fakeyou-refresh {
  //- BS5 classnames -//
  .object-fit-cover {
    object-fit: cover;
  }
  .ratio-1x1 {
    aspect-ratio: 1/1;
  }
  /* Chrome, Safari, Edge, Opera */
  .numberInputNoArrows input::-webkit-outer-spin-button,
  .numberInputNoArrows input::-webkit-inner-spin-button,
  input.numberInputNoArrows::-webkit-outer-spin-button,
  input.numberInputNoArrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  .numberInputNoArrows input[type="number"],
  input.numberInputNoArrows[type="number"] {
    -moz-appearance: textfield;
  }

  //-end--//

  ::-webkit-scrollbar-thumb {
    background-color: rgba($secondary, 1);
    border-radius: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    background-color: $panel !important;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  // body {
  //   overflow-y: scroll;
  // }

  p {
    font-weight: 400;
    margin-bottom: 0;
    color: rgba($white, 0.9);
  }

  a {
    font-weight: 400;
    color: $primary;
    text-decoration: none;
    cursor: pointer;
    transition: 0.1s;

    &:hover {
      color: lighten($primary, 5%);
    }
  }

  .page-title {
    font-size: 2.5rem;
  }

  .btn-link {
    color: $primary;
    text-decoration: none;
    cursor: pointer;
    transition: 0.1s;
    background: none;
    border: none;

    &:hover {
      color: lighten($primary, 5%);
    }
  }

  .btn-square {
    color: $white;
    background-color: $primary;
    height: 42px;
    width: 42px;
    transition: all 0.2s;

    &:hover {
      background-color: lighten($primary, 5%);
    }
  }

  hr {
    border: 0;
    border-top: 1px solid $white;
    opacity: 0.1;
  }

  html.sr .load-hidden {
    visibility: hidden;
  }

  select {
    cursor: pointer;
    border-radius: 0.5rem;
  }

  .no-wrap {
    white-space: nowrap;
  }

  button:focus,
  button:active,
  button:hover,
  label:focus,
  .btn:active,
  .btn.focus {
    outline: 0px !important;
    box-shadow: none !important;
  }

  label {
    &.required:after {
      content: " *";
      color: $primary;
    }
  }

  thead,
  tbody {
    border-color: rgba($white, 0.2) !important;
  }

  th {
    color: $white;
    font-weight: 700;
    vertical-align: text-top;
  }

  td {
    color: rgba($white, 0.8);
    font-weight: 400;
    border-color: rgba($white, 0.12);

    // this is to remove the lingering border on the comment component when there is only one comment -V

    &:only-of-type {
      border: none;
    }
  }

  .table-mw {
    min-width: 200px;
  }

  .rounded {
    border-radius: 0.5rem !important;
  }

  .rounded-5 {
    border-radius: 0.5rem !important;
  }

  code {
    color: $primary;
    background-color: rgba($white, 0.06);
  }

  .text-link {
    color: $primary;
  }

  .text-light {
    color: rgba($white, 0.7) !important;
  }

  .fw-semibold {
    font-weight: 600;
  }

  .fw-medium {
    font-weight: 500;
  }

  .container {
    max-width: 1600px !important;
  }

  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x, 1rem);
    padding-left: var(--bs-gutter-x, 1rem);
  }

  .narrow-container {
    max-width: 900px !important;
  }

  .py-6 {
    padding-top: 1.6rem !important;
    padding-bottom: 0.4rem !important;
  }

  .text-truncate {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }

  .lead {
    color: rgba($white, 0.8);
    font-size: 17px;
  }

  .text-red {
    color: $primary;
  }

  .text-yellow {
    color: #ffe600;
  }

  .fill-secondary {
    fill: $secondary;
  }

  .alert {
    border-radius: 0.5rem !important;
    padding: 0.75rem 1rem;
    font-size: 14px;
    font-weight: 500;
  }

  .alert-pricing {
    font-weight: 500 !important;
    font-size: 18px !important;
    color: $white;
    background-color: $secondary;
    border-color: $secondary;
    padding: 1.2rem !important;
  }

  .alert-cta {
    font-weight: 500 !important;
    font-size: 16px !important;
    border-radius: 0.5rem;
  }

  .btn {
    border-radius: 0.5rem;
    outline: none;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 20px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-primary,
  .btn-primary:focus,
  .btn-primary:active {
    background-color: $primary !important;
    border: 2.5px solid;
    border-color: $primary !important;
    transition: 0.2s;
    color: $white;
  }
  .btn-primary:hover {
    background-color: lighten($primary, 5%) !important;
    border-color: lighten($primary, 5%) !important;
    color: $white;
  }

  .btn-secondary,
  .btn-secondary:focus,
  .btn-secondary:active {
    background-color: $secondary;
    color: $white;
    border: 2.5px solid;
    border-color: $secondary;
    transition: 0.2s;
  }

  .btn-secondary:hover {
    background-color: lighten($secondary, 5%) !important;
    color: $white;
    border: 2.5px solid;
    border-color: lighten($secondary, 5%) !important;
  }

  .btn-secondary.disabled,
  .btn-secondary:disabled {
    color: $white;
    background-color: lighten($secondary, 5%) !important;
    border-color: lighten($secondary, 5%) !important;
  }

  .btn-destructive,
  .btn-destructive:focus,
  .btn-destructive:active {
    background-color: $danger;
    color: $white;
    border: 2.5px solid;
    border-color: $danger;
    transition: 0.2s;
  }

  .btn-destructive:hover {
    background-color: lighten($danger, 5%) !important;
    color: $white;
    border: 2.5px solid;
    border-color: lighten($danger, 5%) !important;
  }

  .btn-inform {
    height: 42px;
    align-items: center;
    padding: 0 0.75rem;
    padding-top: 1px;
    font-size: 22px;
    border-radius: 50px;
  }

  .btn-session-tts-play {
    height: 36px;
    width: 36px;
    padding: 0 12px;
  }

  .btn-explore {
    min-width: 172px;
    border-radius: 50px;
  }

  .bg-panel {
    background-color: lighten($panel, 1%);
  }

  .panel {
    @include panel;
    position: relative;
    width: 100%;

    .video-bg {
      background-color: $black;
    }

    .panel-border {
      border-color: #ffffff12 !important;
      border: 1px solid;
    }

    .panel-inner {
      background-color: $panel-inner;
      box-shadow: none !important;
    }

    .panel-results {
      border-radius: 0.5rem;
      background-color: lighten($panel, 4%);
      box-shadow: none !important;
      transition: all 0.15s;

      &.lipsync-tts-results {
        border-width: 2px;

        &:hover {
          background-color: lighten($panel-inner, 2%);
          cursor: pointer;
          border: 2px solid lighten($panel-inner-border, 15%) !important;
        }
      }
    }

    .panel-title {
      position: static;
      text-align: center;
      text-shadow: 0px 3px 26px #00000060;
      margin-top: -35px;
    }

    h2.panel-title {
      margin-top: -32px;
    }

    a {
      color: lighten($primary, 5%);

      &:hover {
        color: $primary;
      }

      &.btn {
        color: $white;

        &.btn-secondary {
          color: $white;
        }
      }
    }
  }
  .focus-point {
    width: 8px;
    margin-right: 12px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: $primary;
    box-shadow: 0 0 0 0 $primary;
    animation: l1 1s infinite;
  }
  @keyframes l1 {
    100% {
      box-shadow: 0 0 0 18px #0000;
    }
  }

  .panel-clear {
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: transparent;
    border: none;
    border-color: transparent !important;
    box-shadow: none !important;
  }

  //Set Paddings
  .padding {
    padding: 1.4rem;
  }

  .padding-y {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }

  .padding-x {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }

  .padding-top {
    padding-top: 1.4rem;
  }

  .padding-bottom {
    padding-bottom: 1.4rem;
  }

  .padding-left {
    padding-left: 1.4rem;
  }

  .padding-right {
    padding-right: 1.4rem;
  }

  .menu {
    border-radius: 0.5rem !important;
    color: $white;
    font-weight: 400;
    position: absolute;
    border-color: rgba($white, 0.12) !important;
    border: 1px solid;
    border-radius: 0.5rem;
    box-shadow: 0px 5px 20px rgba($black, 0.3);
    z-index: 3;
    overflow: hidden;
  }

  .menu-item {
    padding: 0.25rem;
    padding-left: 2.375rem;
    padding-right: 2.375rem;
    cursor: pointer;
    background-color: $secondary;
    transition: 0.1s;

    &:hover {
      background-color: darken($secondary, 6%);
    }
  }

  .login-panel {
    max-width: 480px !important;
    backdrop-filter: blur(5px);
  }

  .auth-page-left {
    height: auto;
  }

  .auth-page-right {
    padding: 1.25rem;
    padding-bottom: 5rem;
    height: 100vh;
  }

  .sub-title {
    font-weight: 500;
    padding-bottom: 0.5rem;
  }

  .form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
    border-radius: 50px;

    &.main-select {
      height: 48px;
    }
  }

  .form-control {
    border-radius: 0.5rem;
    background-color: $input-dark !important;
    border-color: $input-dark !important;
    color: $white !important;
  }

  .form-control,
  .form-select {
    border-width: 1px;
    border-color: $white;
    padding: 10.25px 0.75rem;

    &:focus {
      border-color: $primary;
      outline: 0;
      box-shadow: 0 0 0 0.1rem $primary;
    }
  }

  .fa-solid,
  .fas {
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
  }

  .form-text {
    margin-top: 10px;
    color: darken($white, 10%);

    &.red {
      color: lighten($primary, 5%);
    }
  }

  .input-icon {
    display: flex;
    align-items: center;

    .form-control {
      padding-left: 2.375rem;
    }

    .form-select {
      padding-left: 2.375rem;
      padding-right: 2.375rem;
    }

    .form-control-feedback {
      position: absolute;
      z-index: 2;
      width: 2.75rem;
      // height: 2.375rem;
      // line-height: 2.69rem;
      text-align: center;
      pointer-events: none;
      color: $secondary;
      opacity: 0.9;
    }
  }

  .social-icon {
    font-size: 18px;
    color: $white;
    text-decoration: none;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      color: rgba($white, 0.6);
    }
  }

  .share-icon {
    transition: 0.2s;

    &:hover {
      opacity: 0.6;
    }
  }

  #resultLink {
    border-radius: 0.5rem 0 0 5px;
  }

  #copyBtn {
    min-width: 105px;
    border-radius: 0 5px 5px 0;
  }

  .firehose-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  ul.patrons-list {
    columns: 1;
    list-style: none;
  }

  .form-check {
    margin-bottom: 0.3rem;

    .form-check-input {
      margin-right: 10px;
    }
  }

  .form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
  }

  .form-check-input:focus {
    box-shadow: none;
  }

  .pricing-border {
    box-sizing: border-box;
    box-shadow: inset 0px 0px 0px 3px $primary !important;
  }

  ul.pricing-list {
    list-style: none;
    padding: 0;

    li {
      font-size: 14px;
    }

    .small-text {
      font-size: 12px;
      font-weight: 400;
      opacity: 0.8;
      color: lighten($primary, 10%);
    }
  }

  ul li {
    @for $i from 1 through 20 {
      &:nth-of-type(#{$i}) {
        animation-delay: $i * 100ms !important;
      }
    }
  }

  dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 0rem;
  }

  .content p:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  tbody tr {
    @for $i from 1 through 12 {
      &:nth-of-type(#{$i}) {
        animation-delay: $i * 20ms !important;
      }
    }
  }

  .video-card {
    border-radius: 0.5rem;
    border: 4px solid;
    border-color: #fff;
    padding: 1rem;
    position: relative;
    width: 100%;
    min-height: 160px;
    transition: 0.2s;
    overflow: hidden;
    z-index: 2;
  }

  .video-card:hover {
    cursor: pointer;
    border-color: #fc6b68;
  }

  .video-card:hover .video-card-body {
    opacity: 1;
  }

  .video-card:hover .video-img {
    transform: scale(1.05);
    transition: 0.2s;
  }

  .video-img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    overflow: hidden;
    transition: 0.2s;
  }

  .video-card-body {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    padding: 0.75rem;
    width: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 32%,
      rgba(0, 0, 0, 1) 100%
    );
    opacity: 0;
    transition: 0.2s;
  }

  .video-card-title {
    font-size: 14px;
    color: $white;
  }

  .video-card-text {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
  }

  .profile-social-icons {
    font-size: 20px;
  }

  .w2l-ani .w2l-ani-item {
    @for $i from 1 through 100 {
      &:nth-of-type(#{$i}) {
        animation-delay: $i * 50ms !important;
      }
    }
  }

  .tts-result-table tbody tr th {
    width: 35%;
  }

  .badge {
    font-weight: 500;
    font-size: 13px;
    padding: 8px 16px;
    border: none;
    border-radius: 50px;

    &.badge-destructive {
      background-color: $white;
      color: $primary;

      &.btn {
        border-radius: 0.5rem;
        font-weight: 600;
        &:hover {
          background-color: #fdcfce;
        }
      }
    }

    &.badge-warning {
      background-color: #fff3cd;
      color: #664d03;
    }

    &.badge-tag {
      font-size: 13px;
      padding: 6px 12px;
      background-color: $input-dark;
      color: $white;
    }
  }

  .badge-model {
    font-weight: 600;
    font-size: 12px;
    padding: 2px 6px;
    border: none;
    border-radius: 0.3rem;
    opacity: 0.85;
    max-height: 18px;

    &.badge-model-svc {
      background-color: #beebe7;
      color: #175e5f;
    }

    &.badge-model-rvc {
      background-color: #fcddc7;
      color: #933b1d;
    }

    &.badge-model-tt2 {
      background-color: #dfe0fa;
      color: #3f47b1;
    }
  }

  .badge-new {
    font-weight: 600;
    font-size: 12px;
    padding: 2px 6px;
    margin-top: -1px !important;
    border: none;
    border-radius: 0.3rem;
    opacity: 0.85;
    background-color: #fff3d0;
    color: #833c00;
  }

  .badge-beta {
    font-weight: 600;
    font-size: 12px;
    padding: 2px 6px;
    margin-top: -1px !important;
    border: none;
    border-radius: 0.3rem;
    opacity: 0.85;
    background-color: #f2e4ff;
    color: #42146d;
  }

  .rounded-circle {
    border-radius: 50px;
  }

  .btn-toggle {
    margin: 0;
    padding: 0;
    color: rgba($white, 0.8);

    &.dark {
      color: rgba($white, 0.8);
    }

    &:hover {
      color: rgba($white, 0.6);
    }
  }

  .session-tts-section {
    // height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;
  }

  .session-vc-section {
    // height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;
  }

  .direct-view-link {
    background-color: lighten($panel, 4%);
    border: 1px solid #39394c;
    border-radius: 0.5rem;
    padding: 12px 16px;
    min-height: 63px;
    height: 100%;

    // &:hover {
    //   border-color: lighten(#39394c, 10%);
    // }
  }

  .bg-translucent {
    background-color: rgba(#1a1a27, 0.8);
  }

  .modal-open {
    overflow-y: hidden;
  }

  .input-icon-search {
    .form-control-feedback {
      padding: 12px 18px;
      position: absolute;
      z-index: 2;
      width: 2.375rem;
      // height: 2.375rem;
      // line-height: 2.69rem;
      text-align: center;
      pointer-events: none;
      color: $white;
      opacity: 0.9;
    }
  }

  .select-search {
    height: 48px;
    border-width: 2px;
    padding-left: 2.25rem;
    padding-right: 0.5rem;
    background-color: $input-dark;
    border-radius: 0.5rem;
    border-color: $input-dark !important;
    box-shadow: none;
    cursor: pointer;

    &.no-padding {
      padding-left: 0.5rem;
    }

    &:hover {
      background-color: lighten($input-dark, 5%);
      border-color: lighten($input-dark, 5%) !important;
    }

    &.focused {
      border-color: $primary !important;
    }
  }

  .select-option {
    background-color: $input-dark;
    cursor: pointer;
    padding: 0.5rem 1rem;

    &:hover {
      background-color: lighten($input-dark, 8%);
    }
  }

  .select-input,
  .select-value {
    color: $white;
  }

  .select-placeholder {
    color: $white;
  }

  .select-container {
    background: $input-dark;
    margin-top: 6px;
    padding: 0;
    border-radius: 0.5rem;
    z-index: 3;
  }

  .select-separator {
    width: 1px;
    opacity: 0.3;
  }

  .rounded-50 {
    border-radius: 50px;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(darken($panel, 8%), 0.8); /* Translucent black */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    z-index: 1050;
  }

  .modal-content {
    overflow: hidden;
    background-color: $panel;
    border-color: lighten($panel, 8%) !important;
    border: 1px solid;
    border-radius: 0.5rem;
    box-shadow: 0px 9px 33px 1px rgba(19, 19, 27, 0.8);
    -webkit-box-shadow: 0px 9px 33px 1px rgba(19, 19, 27, 0.8);
    -moz-box-shadow: 0px 9px 33px 1px rgba(19, 19, 27, 0.8);
  }

  .modal-header .btn-close {
    filter: brightness(0) invert(1);
  }

  .rounded-top-0 {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  #footer {
    background-color: $panel;
    border-top: 1px solid;
    border-color: rgba(255, 255, 255, 0.06);
    margin-top: 8rem;

    li {
      list-style: none;
    }
  }

  .footer-bar {
    font-size: 14px;
    padding: 0.6rem 0;
    background-color: lighten($panel, 2%);
    border-bottom: 1px solid;
    border-color: rgba($white, 0.04);
  }

  .result-text {
    font-size: 18px;
  }

  .btn-rate {
    font-weight: 500;
    font-size: 17px;
    background-color: lighten($secondary, 4%);
    border-radius: 0.5rem;
    border: none;
    padding: 6px 20px;
    color: rgba($white, 0.5);
    transition: 0.1s all;

    &.left {
      border-radius: 0.5rem 0px 0px 0.5rem;
    }

    &.right {
      border-radius: 0px 0.5rem 0.5rem 0px;
    }

    &.rated {
      color: $white;
      background-color: lighten($secondary, 15%);
    }

    &:hover {
      background-color: lighten($secondary, 10%);
    }
  }

  .btn-action {
    font-weight: 500;
    font-size: 14px;
    background-color: lighten($secondary, 4%);
    border-radius: 0.5rem;
    border: none;
    padding: 8.25px 20px;
    color: $white;
    transition: 0.1s all;

    &:hover {
      background-color: lighten($secondary, 10%);
    }

    &:active {
      background-color: lighten($secondary, 15%);
    }
  }

  .rating-icon {
    color: #ffbc39;

    &.heart {
      color: $primary;
    }
  }

  .tippy-box[data-theme~="fakeyou"] {
    background-color: lighten($secondary, 5%);
    color: $white;
    border-radius: 0.4rem;
  }

  .tippy-box[data-theme~="range-slider"] {
    margin-top: -5px;
    background-color: lighten($panel, 10%);
    color: $white;
    font-size: 13px !important;
  }

  .tippy-box[data-theme~="fakeyou"][data-placement^="top"]
    > .tippy-arrow::before {
    border-top-color: lighten($secondary, 5%);
  }
  .tippy-box[data-theme~="fakeyou"][data-placement^="bottom"]
    > .tippy-arrow::before {
    border-bottom-color: lighten($secondary, 5%);
  }
  .tippy-box[data-theme~="fakeyou"][data-placement^="left"]
    > .tippy-arrow::before {
    border-left-color: lighten($secondary, 5%);
  }
  .tippy-box[data-theme~="fakeyou"][data-placement^="right"]
    > .tippy-arrow::before {
    border-right-color: lighten($secondary, 5%);
  }

  .sliding-content {
    overflow: hidden;
    opacity: 0;
    height: 0;
    transition-property: none !important;
    transition: all 0.1s !important;
    transition-timing-function: ease !important;
  }
  .sliding-content.open {
    overflow: visible;
    opacity: 1;
    height: 100%;
    transition-property: none !important;
    transition: all 0.3s !important;
    transition-timing-function: ease !important;
  }

  wave {
    overflow: hidden !important;
  }

  .twitch-video-container {
    border: 1px solid #ffffff12 !important;
    border-radius: 0px;
    overflow: hidden;
    background-color: $panel !important;
    min-height: 220px;
    height: 100%;
    box-shadow:
      0 0 0 1px rgba(0 0 0 / 30%),
      0 70px 65px rgb(0 0 0 / 10%),
      0 30px 30px rgb(0 0 0 / 8%),
      0 15px 15px rgb(0 0 0 / 6%),
      0 10px 8px rgb(0 0 0 / 4%),
      0 4px 4px rgb(0 0 0 / 2%),
      0 2px 2px rgb(0 0 0 / 1%) !important;
  }

  .twitch-chat-container {
    border: 1px solid #ffffff12 !important;
    border-radius: 0.5rem;
    background-color: $panel !important;
    min-height: 650px;
    box-shadow:
      0 0 0 1px rgba(0 0 0 / 30%),
      0 70px 65px rgb(0 0 0 / 10%),
      0 30px 30px rgb(0 0 0 / 8%),
      0 15px 15px rgb(0 0 0 / 6%),
      0 10px 8px rgb(0 0 0 / 4%),
      0 4px 4px rgb(0 0 0 / 2%),
      0 2px 2px rgb(0 0 0 / 1%) !important;
  }

  .channel-title {
    font-size: 16px;
  }

  .stream-title {
    font-size: 14px;
  }

  .channel-description {
    font-size: 14px;
  }

  .channel-image {
    border: 2px solid $white;
    min-width: 60px;
    min-height: 60px;
    height: 60px;
    width: 60px;
    object-fit: cover;
  }

  .view-count {
    font-size: 14px;
    color: #fc6b68;
  }

  .hero-img-container {
    position: relative;
    min-height: 100%;
    overflow: hidden;
  }

  .hero-img {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 95%;
  }

  .hero-img-mobile {
    pointer-events: none;
    width: 65%;
    margin-bottom: -4%;
    margin-top: -30%;
  }

  .border-thick {
    border-width: 2px;
  }

  //Nav Tabs (Profile)

  .nav-profile {
    flex-wrap: nowrap;
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  }

  .nav-tabs {
    border-bottom: 2px solid #ffffff15;

    .nav-link {
      border: none;
      padding: 1rem 1.4rem;
      border-bottom: 4px solid transparent;
      color: rgba($white, 0.5);
      font-weight: 500;
      white-space: nowrap;

      &:hover {
        color: rgba($white, 0.75);
      }

      &.active {
        color: $white;
        background-color: transparent;
        border-bottom: 4px solid $primary;
      }
    }
  }

  .fs-7 {
    font-size: 14px !important;
  }

  .channel-thumbnail {
    border-radius: 0.5rem;
    border: 1px solid #ffffff15;
    overflow: hidden;
    transition: all 0.2s;

    img {
      width: 100%;
      object-fit: cover;
    }

    &:hover {
      transform: scale(1.04);
    }
  }

  .channel-profile {
    border-radius: 60px;
    overflow: hidden;
    border: 2px solid #ffffff15;
    width: 40px;
    height: 40px;
    object-fit: cover;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .channel-title {
    color: $white;
    font-size: 16px;
    margin: 0;
    padding: 0;
    margin-bottom: 2px;
  }

  .channel-name {
    color: rgba($white, 0.75);
    font-size: 13px;
    padding: 0;
  }

  .profile-model-title {
    color: $white !important;

    &:hover {
      color: $primary !important;
    }
  }

  .textarea-comment {
    resize: none;
    overflow-x: none;
  }

  .btn-comment {
    padding: 8px 20px;
    font-size: 14px;
  }

  .btn-link-white {
    color: $white;
    opacity: 0.75;

    &:hover {
      color: $white;
      opacity: 0.5;
    }
  }

  .btn-link-small {
    font-size: 14px;
  }

  .comment-time {
    font-size: 14px;
  }

  .gravatar-img {
    pointer-events: none;
  }

  .upload-component {
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;

    .panel {
      box-shadow: none !important;
    }

    .upload-zone {
      height: 100%;
      border: dashed 2px rgba($white, 0.12) !important;
      border-radius: 0.5rem;
      transition: 0.1s all;

      &:hover {
        background-color: lighten($panel, 6%);
        cursor: pointer;
      }

      &.empty-zone {
        height: 323px;

        @media (max-width: 768px) {
          height: 100px;
        }
      }

      &.empty-zone-short {
        height: 120px;

        @media (max-width: 768px) {
          height: 100px;
        }
      }
    }

    .upload-zone-drag {
      background-color: $white !important;
    }

    .filename {
      display: block;
      max-width: 230px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .supported-files {
      font-size: 12px;
    }

    .upload-icon {
      font-size: 42px;
    }
  }

  .file-size {
    font-size: 14px;
    color: rgba($white, 0.75);
    padding: 4px 8px;
    border-radius: 5px;
    background-color: $secondary;
  }

  .nav-vc {
    flex-wrap: nowrap;
    height: auto;
    overflow-y: hidden;
    overflow-x: auto;
  }

  .video-container {
    border: 2px solid;
    border-color: #ffffff20;
    border-radius: 0.5rem;
    overflow: hidden;
    z-index: 2;
  }

  .section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .mw-300 {
    max-width: 300px !important;
  }

  .cta-container {
    position: relative;

    .chevrons-grey {
      position: absolute;
      top: 8%;
      left: -45%;
      pointer-events: none;
    }

    .chevrons-red {
      position: absolute;
      top: 8%;
      right: -45%;
      pointer-events: none;
    }

    .hanashi-before {
      position: absolute;
      top: -80%;
      left: -150%;
      pointer-events: none;
    }

    .hanashi-after {
      position: absolute;
      top: -80%;
      right: -150%;
      pointer-events: none;
    }
  }

  .community-container {
    position: relative;
    z-index: 2;
  }

  .cta-mobile {
    margin-top: -20px;
  }

  .dots-right-top {
    position: absolute;
    width: auto;
    right: -48px;
    top: -32px;
    pointer-events: none;
  }

  .dots-right-bottom {
    position: absolute;
    width: auto;
    right: -48px;
    bottom: -32px;
    pointer-events: none;
  }

  .dots-left-bottom {
    position: absolute;
    width: auto;
    left: -48px;
    bottom: -32px;
    pointer-events: none;
  }

  .btn-discord {
    background-color: #404eed !important;
    transition: 0.2s all;

    &:hover {
      background-color: lighten(#404eed, 5%) !important;
    }
  }

  .btn-twitter {
    background-color: #1d9bf0 !important;
    transition: 0.2s all;

    &:hover {
      background-color: lighten(#1d9bf0, 10%) !important;
    }
  }

  .panel-select {
    transition: 0.2s all;
    border-radius: 0.5rem !important;
    border-width: 2px !important;
    height: 330px;
    overflow: hidden;
    z-index: 2;

    &:hover {
      border-color: $primary !important;
      transition: 0.2s all;
    }

    img {
      transition: 0.2s all;
      pointer-events: none;
    }

    &:hover > img {
      transition: 0.2s all;
      transform: scale(1.05);
    }

    &:hover .btn {
      transition: 0.2s all;
      background-color: lighten($primary, 5%);
    }

    .btn-icon {
      transition: 0.2s all;
    }

    &:hover .btn .btn-icon {
      transform: translateX(3px);
    }

    @media (max-width: 768px) {
      height: 280px;
    }
  }

  .fs-14 {
    font-size: 14px;
  }

  .range-slider-value {
    text-align: center;
    max-width: 50px;
    padding: 6px;
    border-radius: 8px;
    pointer-events: none;
    background-color: lighten($panel, 5%) !important;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.12) !important;
    color: rgba($white, 0.85) !important;
    font-weight: 500 !important;
  }

  .bg-stepper {
    background-color: lighten($panel, 4%);
    border-radius: 0.5rem 0.5rem 0rem 0rem;
  }

  .stepper-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .stepper-number {
      font-size: 20px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      min-width: 36px;
      background-color: $secondary;
      border-radius: 48px;
      opacity: 0.4;
      transition: all 0.2s;

      &.active {
        background-color: $primary;
        opacity: 1;
      }
    }

    .stepper-description {
      font-size: 18px;
      font-weight: 600;
      white-space: nowrap;
      opacity: 0.4;
      transition: all 0.2s;

      &.active {
        opacity: 1;
      }
    }

    .stepper-line {
      flex-grow: 1;
      height: 3px;
      background: $secondary;
      transition: all 0.2s;
      min-width: 80px;

      &.active {
        background-color: $primary;
        opacity: 1;
      }
    }
  }

  @media (max-width: 768px) {
    //Set Paddings
    .padding {
      padding: 1rem;
    }

    .padding-y {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .padding-x {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .padding-top {
      padding-top: 1rem;
    }

    .padding-bottom {
      padding-bottom: 1rem;
    }

    .padding-left {
      padding-left: 1rem;
    }

    .padding-right {
      padding-right: 1rem;
    }
  }
}

.container-panel {
  overflow: hidden;
}

.container-panel-full {
  overflow: hidden;
}

select {
  border-color: $input-dark !important;
  background-color: $input-dark !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  transition: 0.1s !important;

  &:hover {
    background-color: lighten($input-dark, 5%) !important;
  }

  option {
    background-color: $input-dark !important;
  }
}

.btn-destructive {
  background-color: $danger !important;
}

.btn-uploaded {
  background-color: lighten($secondary, 5%) !important;
  border-color: lighten($secondary, 5%) !important;
  color: $white !important;
}

.video-card-title,
.video-card-text {
  color: $white !important;
}

textarea {
  background-color: $input-dark !important;
  border-color: $input-dark !important;
  color: $white !important;

  &.text-message {
    font-size: 18px;
    font-weight: 400;
    min-height: 200px !important;
  }
}

.form-select {
  color: $white !important;
  border-radius: 50%;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba($white, 0.5) !important;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba($white, 0.5) !important;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba($white, 0.5) !important;
}

.input-icon .form-control-feedback {
  color: $white !important;
}

.img-product {
  &.img-tts {
    margin-top: -82px;
    max-height: 240px;
  }

  &.img-channels {
    margin-top: -82px;
    max-height: 240px;
  }
}

.index-title {
  line-height: 5;
}

.mw-lead {
  max-width: 480px;
}

.zi-1 {
  position: relative;
  z-index: 1 !important;
}

.zi-2 {
  position: relative;
  z-index: 2 !important;
}

.zi-3 {
  position: relative;
  z-index: 3 !important;
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (min-width: 992px) {
  .fakeyou-refresh {
    .panel {
      .panel-title {
        position: absolute;
        text-align: left;
        top: 0;
        margin-top: -25px;
      }

      h2.panel-title {
        margin-top: -20px;
      }
    }

    .auth-page-left {
      height: 100vh;
    }

    .auth-page-right {
      padding: 5.2rem;
    }

    .lead {
      font-size: 18px;
    }

    .session-tts-section {
      height: 400px;
    }

    .session-vc-section {
      height: 511px;
    }

    .session-f5-section {
      height: 637px;
    }

    .session-tts-section::-webkit-scrollbar {
      width: 10px;
    }

    .session-tts-section::-webkit-scrollbar-track {
      background-color: darken($panel, 2%);
      border-radius: 20px;
    }

    .session-tts-section::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: $secondary;

      &:hover {
        background-color: lighten($secondary, 5%);
      }
    }

    .section {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
  }
}

@media (min-width: 768px) {
  .fakeyou-refresh {
    .container-panel {
      width: 100%;
      padding-right: var(--bs-gutter-x, 1.75rem);
      padding-left: var(--bs-gutter-x, 1.75rem);
      margin-right: auto;
      margin-left: auto;
      max-width: 1600px;
      border-radius: 0.5rem;
      overflow: hidden;
    }

    .container-panel-full {
      padding-right: var(--bs-gutter-x, 1.75rem);
      padding-left: var(--bs-gutter-x, 1.75rem);
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      border-radius: 0.5rem;
      overflow: hidden;
    }

    .panel {
      border-radius: 0.5rem;
      border: 1px solid;
    }

    .panel-clear {
      padding-left: 0rem;
      padding-right: 0rem;
    }

    ul.patrons-list {
      columns: 3;
    }

    .video-card {
      min-height: 220px;
    }

    .twitch-video-container {
      min-height: 391px;
      height: 100%;
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  .fakeyou-refresh {
    .prev-step {
      display: none !important;
    }
  }
}

.fy-studio-frame {
  border-radius: 0.5rem;
  width: 100%;
  height: 500px;
}
