@import "scss/colours.scss";

.searcher-container {
  &.in-modal {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 65vh;
  }
}

@media screen and (max-width: 992px) {
  .searcher-container {
    &.in-modal {
      max-height: 70vh;
    }
  }
}
