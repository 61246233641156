@import "scss/colours.scss";

.button {
  border-radius: 0.5rem;
  outline: none;
  font-size: 15px !important;
  font-weight: 600 !important;
  padding: 10px 20px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white !important;

  &-square {
    width: 46.5px;
    height: 46.5px;
    padding: 0;
  }

  &-square-small {
    width: 37.5px;
    height: 37.5px;
    padding: 0;
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.button-primary,
.button-primary:focus,
.button-primary:active {
  background-color: $primary;
  border: 2.5px solid;
  border-color: $primary;
  transition: 0.2s;
  color: $white !important;
}

.button-primary:hover {
  background-color: lighten($primary, 5%);
  border-color: lighten($primary, 5%);
  color: $white !important;
}

.button-secondary,
.button-secondary:focus,
.button-secondary:active {
  background-color: $secondary;
  color: $white !important;
  border: 2.5px solid;
  border-color: $secondary;
  transition: 0.2s;
}

.button-secondary:hover {
  background-color: lighten($secondary, 5%);
  color: $white !important;
  border: 2.5px solid;
  border-color: lighten($secondary, 5%);
}

.button-action,
.button-action:focus,
.button-action:active {
  background-color: $action;
  color: $white !important;
  border: 2.5px solid;
  border-color: $action;
  transition: 0.2s;
}

.button-action:hover {
  background-color: lighten($action, 5%);
  color: $white !important;
  border: 2.5px solid;
  border-color: lighten($action, 5%);
}

.button-discord,
.button-discord:focus,
.button-discord:active {
  background-color: #5865f2;
  color: $white !important;
  border: 2.5px solid;
  border-color: #5865f2;
  transition: 0.2s;
}

.button-discord:hover {
  background-color: #6b78ff;
  color: $white !important;
  border: 2.5px solid;
  border-color: #6b78ff;
}

.button-danger,
.button-danger:focus,
.button-danger:active {
  background-color: $danger;
  color: $white !important;
  border: 2.5px solid;
  border-color: $danger;
  transition: 0.2s;
}

.button-danger:hover {
  background-color: lighten($danger, 5%);
  color: $white !important;
  border: 2.5px solid;
  border-color: lighten($danger, 5%);
}

.button-small,
.button-small:focus,
.button-small:active {
  font-weight: 500;
  font-size: 14px !important;
  padding: 8.25px 16px;
  transition: 0.2s;
  border: none;
}

.button-small:hover {
  border: none;
}

.button-font-large {
  font-size: 18px !important;
}

.button-link {
  border: none;
  border-radius: 0;
  background-color: transparent;
  padding: 0 !important;
  color: $primary !important;
  font-size: 16px !important;
  transition: all 0.2s;
  white-space: nowrap;

  &:hover {
    color: lighten($primary, 6%) !important;
  }
}

// From https://codepen.io/lemmin/pen/WObwRX/
.button-rainbow {
  //width:calc(20vw + 6px);
  //height:calc(8vw + 6px);
  background-image: linear-gradient(
    90deg,
    #00c0ff 0%,
    #ffcf00 49%,
    #fc4f4f 80%,
    #00c0ff 100%
  );
  //border-radius:5px;
  border: 2.5px solid rgba($white, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 3vw;
  font-weight: bold;
  text-shadow:
    -1px 0 black,
    0 1px black,
    1px 0 black,
    0 -1px black;

  &:hover {
    animation: slidebg 2s linear infinite;
    border: 2.5px solid gold;
    color: black !important;
    text-shadow:
      -1px 0 gold,
      0 1px gold,
      1px 0 gold,
      0 -1px gold;
  }
}
.button-rainbow:after {
  content: attr(alt);
  //width:20vw;
  //height:8vw;
  background-color: #191919;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

.card .button-link {
  padding: 4px 8px;
  font-size: 13px !important;
  color: $white !important;
  background-color: $primary;
  border-radius: 0.5rem;

  &:hover {
    background-color: lighten($primary, 5%);
  }
}

/* Disabled styles */
.btn-primary:disabled,
.btn-secondary:disabled,
.btn-danger:disabled,
.btn-small:disabled,
.btn-link:disabled {
  @extend .disabled;
}
